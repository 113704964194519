import React from "react";
import useComponentSize from "../../modules/hooks/use-component-size";
import { scaleLinear, scaleBand, scalePoint } from "d3-scale";
import styles from "./vertical-performance.module.scss";
import SvgFlag from "../../components/svg-flag";
import {
    HexagonPolygon,
    HexagonPolygonSmall,
    HexagonSvg,
    SquarePolygon,
    DiamondPolygon,
    SquareSvg,
    DiamondSvg,
    Arrow,
} from "../../components/shapes";
import Flag from "../../components/flag";
import { significanceColumn } from "../../modules/significance";
import { formatBreaks } from "../../modules/format";

const padding = {
    top: 54,
    bottom: 4,
    left: 44,
    right: 0,
};

const DATUMS = ["school", "country", "oecd"];
const sectionPadding = 40;

const useDomains = (schoolData, visOptions) => {
    try {
        const bands = visOptions.domains.map(domain =>
            visOptions.domainLabelsFromData ? schoolData[domain.label] : domain.label
        );
        let yRaw = visOptions.rangeColumns.map((d, i) => {
            if (schoolData[d] !== undefined) {
                return schoolData[d];
            }
            console.log("----- ----- Couldn't find " + d + " in schoolData");
            console.log("----- ----- Making useDomains " + i * 50);
            return i * 50;
        });

        // We round to nearest 50s.
        const y = [Math.floor(yRaw[0] / 50) * 50, Math.ceil(yRaw[1] / 50) * 50];
        const innerPoints = ["school", "country", "oecd"];
        return {
            bands,
            innerPoints,
            y,
        };
    } catch (e) {
        console.log(
            "Most likely domains has not been defined in visOptions. This is visOptions: " + JSON.stringify(visOptions)
        );
    }
};

const useScales = (dimensions, domains, visOptions) => {
    if (!dimensions) return undefined;

    const frame = {
        top: padding.top,
        right: dimensions.width - padding.right,
        bottom: dimensions.height - padding.bottom,
        left: padding.left,
    };

    const rangeWidth = frame.right - frame.left;

    const bands = scaleBand()
        .domain(domains.bands)
        .rangeRound([frame.left, frame.right])
        .paddingOuter(0)
        .paddingInner(sectionPadding / rangeWidth);

    const bandwidth = bands.bandwidth();

    const y = scaleLinear().domain(domains.y).rangeRound([frame.bottom, frame.top]).nice();

    const innerPoints = scalePoint().domain(domains.innerPoints).rangeRound([0, bandwidth]).padding(1);

    return {
        frame,
        bands,
        innerPoints,
        y,
        bandwidth,
        icon: {
            square: visOptions.showRanges ? 8 : 12,
            diamond: visOptions.showRanges ? 11 : 15,
        },
    };
};

// TODO: we have tthe overallSignificance: "ind_Fig_36" prop
const VerticalPerformance = props => {
    console.log("----- Starting to render VerticalPerformance: " + props.id);
    const { visOptions, schoolData, localiser } = props;
    const ref = React.useRef();
    const dimensions = useComponentSize(ref);
    const domains = useDomains(schoolData, visOptions);
    const scales = useScales(dimensions, domains, visOptions);

    const chartProps = {
        ...props,
        dimensions,
        domains,
        scales,
    };

    let chartContent;
    let overlayContent;
    if (scales) {
        chartContent = (
            <>
                <AxisGrid {...chartProps} />
                <ConfidenceIntervals {...chartProps} />
                <ChartData {...chartProps} />
            </>
        );
        overlayContent = (
            <>
                <DomainLabels {...chartProps} />
            </>
        );
    }

    // TODO: more general overall significance
    ["Note: ", "There are not enough boys/girls to make a statistically significant comparison"].forEach(localiser);

    return (
        <div className={styles.verticalPerformance} data-ranges={visOptions.showRanges}>
            <div className={styles.container}>
                <svg className={styles.svg} ref={ref}>
                    {chartContent}
                </svg>
                <div className={styles.overlay}>{overlayContent}</div>
            </div>
            <Legend {...chartProps} />
            {visOptions.overallSignificance && schoolData[visOptions.overallSignificance] === 0 && (
                <p className={styles.overallSignificance} style={{ paddingLeft: padding.left }}>
                    <b>{localiser("Note: ")}</b>
                    {localiser("There are not enough boys/girls to make a statistically significant comparison")}
                </p>
            )}
        </div>
    );
};

const AxisGrid = props => {
    const { visOptions, localiser, scales } = props;

    const yTicks = scales.y.ticks();

    // Lines protrude past the axis a bit.
    const linesStartX = scales.frame.left - 8;

    return (
        <g>
            <g>
                {yTicks.map((yVal, i) => (
                    <line
                        key={yVal}
                        className={"gridLine"}
                        x1={linesStartX}
                        x2={scales.frame.right}
                        y1={scales.y(yVal)}
                        y2={scales.y(yVal)}
                        data-bottom={i === 0}
                    />
                ))}
            </g>
            <g>
                {yTicks.map(yVal => (
                    <text key={yVal} className={"gridLabel"} x={linesStartX - 8} y={scales.y(yVal)}>
                        {yVal}
                    </text>
                ))}
            </g>
            <g>
                <line
                    className={"axisLine"}
                    x1={scales.frame.left}
                    x2={scales.frame.left}
                    y1={scales.frame.top - 32}
                    y2={scales.frame.bottom}
                />
                <text className={"axisText"} x={0} y={scales.frame.top - 41}>
                    {localiser(visOptions.yAxisLabel || "PISA score")}
                </text>
            </g>
        </g>
    );
};

const ConfidenceIntervals = props => {
    const { scales, visOptions, schoolData } = props;

    const intervals = visOptions.domains
        .filter(d => d.columns.confidenceInterval)
        .map(domain => {
            const { confidenceInterval } = domain.columns;
            const label = visOptions.domainLabelsFromData ? schoolData[domain.label] : domain.label;

            const top = scales.y(schoolData[confidenceInterval[1]]);
            const bottom = scales.y(schoolData[confidenceInterval[0]]);
            const height = bottom - top;
            const x = scales.bands(label);
            const endX = x + scales.bandwidth;

            return {
                ...domain,
                top,
                bottom,
                height,
                x,
                endX,
            };
        });

    const rects = intervals.map((domain, i) => {
        return (
            <g key={domain.label}>
                <rect
                    className={"confidenceIntervalRect"}
                    x={domain.x}
                    width={scales.bandwidth}
                    y={domain.top}
                    height={domain.height}
                />
                <line
                    className={"confidenceIntervalLine"}
                    x1={domain.x}
                    x2={domain.endX}
                    y1={domain.top}
                    y2={domain.top}
                />
                <line
                    className={"confidenceIntervalLine"}
                    x1={domain.x}
                    x2={domain.endX}
                    y1={domain.bottom}
                    y2={domain.bottom}
                />
                {i === 0 && <ConfidenceIntervalExplainer {...props} domain={domain} />}
            </g>
        );
    });
    return <g>{rects}</g>;
};

const ConfidenceIntervalExplainer = props => {
    const { scales, domain, localiser } = props;

    //AL: the collisions that factor into lowerTop are calculated here:
    //AL: I would alter this code to move the Statistically significant label:    


    const left = domain.x + 8;

    //AL: the below is calculating the lowerTop:

    const lowerTop = domain.bottom + scales.icon.square + 10; // collisions[collisions.length - 1] + 12 + 14 + scales.icon.square;
    const upperBottom = domain.top - scales.icon.square; // collisions[0] - 12 - scales.icon.square;

    return (
        <g>
            <g transform={`translate(${left} ${upperBottom})`}>
                <text x={0} y={0} className={styles.confidenceExplainerLabel}>
                    {localiser("Statistically significant")}
                </text>
                <Arrow transform={`translate(10 -24) rotate(180)`} />
            </g>
            
            {/* AL: lowerTop is being used here: */}
            
            <g transform={`translate(${left} ${lowerTop})`}>
                <text x={0} y={0} className={styles.confidenceExplainerLabel}>
                    {localiser("Statistically significant")}
                </text>
                <Arrow transform={`translate(0 12)`} />
            </g>

        </g>
    );
};


const ChartData = props => {
    const { scales, visOptions, params, schoolData } = props;
    const domainIcons = visOptions.domains.map(domain => {
        const label = visOptions.domainLabelsFromData ? schoolData[domain.label] : domain.label;

        const domainLeft = scales.bands(label);
        const domainDatums = DATUMS.map(datumName => {
            const value = schoolData[domain.columns[datumName]];
            const datumOffsetX = scales.innerPoints(datumName);
            const x = domainLeft + datumOffsetX;
            const y = scales.y(value);

            if (schoolData[domain.columns[datumName]] === undefined) {
                console.log("----- ----- Couldn't find " + domain.columns[datumName] + " in schoolData");
                console.log("----- ----- Not rendering ChartData");
            } else {
                if (datumName === "country") {
                    return (
                        <g key={datumName}>
                            <SvgFlag
                                className={styles.chartDatum}
                                params={params}
                                x={x - scales.icon.square}
                                y={y - scales.icon.square}
                                width={scales.icon.square * 2}
                                height={scales.icon.square * 2}
                                data-datum={datumName}
                            />
                            <circle className={"flagStroke"} cx={x} cy={y} r={scales.icon.square} />
                        </g>
                    );
                }

                if (datumName === "school") {
                    const HexagonPolyComponent = visOptions.showRanges ? HexagonPolygonSmall : HexagonPolygon;
                    return (
                        <HexagonPolyComponent
                            key={datumName}
                            className={styles.chartDatum}
                            transform={`translate(${x - scales.icon.square} ${y - scales.icon.square})`}
                            data-datum={datumName}
                        />
                    );
                }
            }

            // OECD
            return (
                <circle
                    key={datumName}
                    className={styles.chartDatum}
                    cx={x}
                    cy={y}
                    r={scales.icon.square}
                    data-datum={datumName}
                />
            );
        });

        return <g key={label}>{domainDatums}</g>;
    });

    const domainRanges =
        visOptions.showRanges &&
        visOptions.domains.map(domain => {
            return <DataRange key={domain.label} domain={domain} {...props} />;
        });

    return (
        <g>
            <g>{domainRanges}</g>
            <g>{domainIcons}</g>
        </g>
    );
};

const DataRange = props => {
    const { domain, schoolData, scales, visOptions } = props;
    const label = visOptions.domainLabelsFromData ? schoolData[domain.label] : domain.label;

    const domainLeft = scales.bands(label);

    const shapes = DATUMS.map(datumName => {
        const values = domain.ranges[datumName].map(d => ({
            value: schoolData[d.value],
            significantCol: d.significant,
        }));

        if (values.some(d => d.value === undefined)) return null;

        const datumOffsetX = scales.innerPoints(datumName);
        const x = domainLeft + datumOffsetX;

        const datumShapes = values.map((val, index) => {
            const y = scales.y(val.value);

            const ShapeComponent = index === 0 ? DiamondPolygon : SquarePolygon;
            const radius = index === 0 ? scales.icon.diamond : scales.icon.square;
            return (
                <ShapeComponent
                    key={index}
                    className={styles.rangeShape}
                    transform={`translate(${x - radius} ${y - radius})`}
                    data-rotate={index === 0}
                    data-significant={significanceColumn(schoolData, val.significantCol)}
                    style={{
                        transformOrigin: `${x}px ${y}px`,
                    }}
                />
            );
        });

        const lineValues = values.map(val => scales.y(val.value));

        const line = <line className={styles.rangeLine} y1={lineValues[0]} y2={lineValues[1]} x1={x} x2={x} />;

        return (
            <g key={datumName}>
                {line}
                <g>{datumShapes}</g>
            </g>
        );
    });

    return <g>{shapes}</g>;
};

const DomainLabels = props => {
    const { localiser, scales, visOptions, schoolData } = props;
    const domainLabels = visOptions.domains.map(domain => {
        const label = visOptions.domainLabelsFromData ? schoolData[domain.label] : domain.label;
        const domainLeft = scales.bands(label);
        const domainMid = domainLeft + scales.bandwidth / 2;

        return (
            <div
                key={label}
                className={styles.domainLabel}
                style={{
                    left: domainMid,
                    top: scales.frame.top - 10,
                    maxWidth: scales.bands.bandwidth() * 0.85,
                }}
            >
                {formatBreaks(localiser(label))}
            </div>
        );
    });

    return domainLabels;
};

const Legend = props => {
    const { batchConfig, localiser, visOptions, params } = props;
    let showConfidenceInterval = true;

    try {
        showConfidenceInterval = visOptions.domains.findIndex(d => d.columns.confidenceInterval) >= 0;
    } catch (e) {
        console.log(
            "Most likely domains has not been defined in visOptions. confidenceInterval is showing by default. This is visOptions: " +
                JSON.stringify(visOptions)
        );
    }

    return (
        <div className={"legend"} style={{ paddingLeft: padding.left }}>
            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <HexagonSvg className={"legendIcon"} data-school />
                </div>
                <span className={"legendLabel"} data-nowrap>
                    {props.yourSchool}
                </span>
            </div>
            {showConfidenceInterval && (
                <div className={"legendItem"}>
                    <div className={"legendIconGroup"}>
                        <div className={styles.legendConfidenceInterval} />
                    </div>
                    <span className={"legendLabel"}>{localiser("95% confidence interval")}</span>
                </div>
            )}
            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <Flag className={"legendIcon"} {...params} />
                </div>
                <span className={"legendLabel"} data-nowrap>
                    {batchConfig.countryName}
                </span>
            </div>

            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <div className={"legendOecdIcon"} />
                </div>
                <span className={"legendLabel"} data-nowrap>
                    {localiser("OECD")}
                </span>
            </div>
            {visOptions.showRanges && (
                <>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"} style={{ height: 21 }}>
                            <DiamondSvg className={"legendRangeIcon"} data-significant="false" />
                            <DiamondSvg className={"legendRangeIcon"} />
                        </div>
                        <span className={"legendLabel"}>{localiser(visOptions.rangeLabels[0])}</span>
                    </div>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"}>
                            <SquareSvg className={"legendRangeIcon"} data-significant="false" />
                            <SquareSvg className={"legendRangeIcon"} />
                        </div>
                        <span className={"legendLabel"}>{localiser(visOptions.rangeLabels[1])}</span>
                    </div>
                </>
            )}
        </div>
    );
};

export default VerticalPerformance;
