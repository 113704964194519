import React from "react";
import { API_URL } from "../modules/fetch-data";

const Flag = props => {
    const { year, countryCode, batchNumber, className } = props;
    const flagUrl = `${API_URL}/flag/${year}/${countryCode}/${batchNumber}.svg`;

    return <img src={flagUrl} className={className} alt="" style={{ borderRadius: "50%", border: "1px solid #777" }} />;
};

export default Flag;
