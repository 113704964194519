/**
 *
 * @param {*} batchLocalisation A JSON dictionary with english keys and their localised replacement.
 * @param {string}  Figure ID.
 */

import slugify from "slugify";
import compareVersion from "compare-versions";

const smuSlug = str => {
    return slugify(
        str
            .toLowerCase()
            .replace(/\|/g, " ")
            .replace(/:/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/'/g, "")
            .replace(/’/g, "")
            .replace(/>/g, "")
            .replace(/</g, "")
            .replace(/\./g, "")
    );
};

export function makeLocaliser(batchLocalisation, figure) {
    if (process.env.NODE_ENV === "development") {
        window._smuGetLocalisation = () => {
            return { allLocalisations, missingLocalisations };
        };
        window._smuGenerateLocalisationJson = () => {
            return Object.entries(allLocalisations).reduce(
                (acc, kvp) => ({
                    ...acc,
                    [smuSlug(kvp[0])]: kvp[0],
                }),
                {}
            );
        };
        window._smuGenerateLocalisationSheet = () => {
            const arr = Object.entries(allLocalisations).map(([key, figures]) => ({
                input: key,
                figures: figures.join(", "),
                rawFigures: figures,
            }));
            const rows = arr
                .sort((a, b) => {
                    if (isNaN(a.rawFigures[0])) return b < a;
                    if (isNaN(b.rawFigures[0])) return b < a;
                    return compareVersion(a.rawFigures[0], b.rawFigures[0]);
                })
                .map(row => {
                    return [smuSlug(row.input), row.figures, row.input, "-"];
                });
            const rowsStr = rows.map(d => d.join("\t")).join("\n");
            const asStr = `Key\tFigures\t"English Example"\tLocalised\n` + rowsStr;

            return asStr;
        };
    }

    return englishInput => {
        // Lookup based on the slug
        const stringKey = smuSlug(englishInput);
        if (process.env.NODE_ENV === "development") {
            if (stringKey === undefined) {
                throw new Error("Undefined localisation!");
            }
            if (!allLocalisations[englishInput]) {
                allLocalisations[englishInput] = [];
            }
            if (!allLocalisations[englishInput].includes(figure)) {
                allLocalisations[englishInput].push(figure);
            }

            if (!batchLocalisation[stringKey]) {
                if (!missingLocalisations[englishInput]) {
                    missingLocalisations[englishInput] = [];
                }
                if (!missingLocalisations[englishInput].includes(figure)) {
                    missingLocalisations[englishInput].push(figure);
                }
            }
        }
        if (batchLocalisation[stringKey]) {
            return batchLocalisation[stringKey];
        } else {
            console.log("----- Missing localisation: " + stringKey);
            return "Missing Localisation: " + stringKey;
        }
    };
}

let missingLocalisations = {};
let allLocalisations = {};
