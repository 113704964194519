import React from "react";
import useComponentSize from "../../modules/hooks/use-component-size";
import { scaleLinear, scaleBand } from "d3-scale";
import { HexagonPolygonSmall, HexagonSvg } from "../../components/shapes";
import { significanceColumn } from "../../modules/significance";
import styles from "./grouped-horizontal-percentage.module.scss";

let DATUM_ORDER = ["school", "oecd", "country"];
const PARA_WIDTH = 330;
const PARA_RIGHT_PADDING = 16;

const padding = {
    top: 66,
    bottom: 0,
    left: PARA_WIDTH + PARA_RIGHT_PADDING,
    right: 36,
};

const SCHOOL_RADIUS = 8;
const CIRCLE_RADIUS = 7;
const DIAMOND_WIDTH = 5;

const BAND_HEIGHT = 26;
const BAND_PADDING = 20;
const GROUP_PADDING = 48;
const BAND_START_OFFSET = 24;

const useDomains = (schoolData, visOptions) => {
    try {
        const groupBands = visOptions.groups.map(g => g.label);
        const domains = visOptions.groups.map(g => g.domains.map(d => d.label));
        const x = [0, 100];
        return {
            groupBands,
            domains,
            x,
        };
    } catch (e) {
        console.log(
            "Most likely domains has not been defined in visOptions. This is visOptions: " + JSON.stringify(visOptions)
        );
    }
};

const useScales = (dimensions, domains, visOptions) => {
    if (!dimensions) return undefined;

    const innerHeight = domains.groupBands.reduce((acc, n, i) => {
        const bottomPadding = i === 0 ? 0 : GROUP_PADDING;
        const bandHeights = domains.domains[i].length * (BAND_HEIGHT + BAND_PADDING * 2);

        return acc + BAND_START_OFFSET + bottomPadding + bandHeights;
    }, 0);
    const totalHeight = padding.top + innerHeight + padding.bottom;

    const frame = {
        top: padding.top,
        right: dimensions.width - padding.right,
        bottom: padding.top + innerHeight,
        left: padding.left,
    };

    let yStart = frame.top + BAND_START_OFFSET;
    const bands = domains.groupBands.map((group, i) => {
        const top = yStart;
        const bandHeights = domains.domains[i].length * (BAND_HEIGHT + BAND_PADDING * 2);
        const bottom = top + bandHeights;

        yStart = bottom + GROUP_PADDING;

        return scaleBand()
            .domain(domains.domains[i])
            .rangeRound([top, bottom])
            .paddingOuter(0)
            .paddingInner(BAND_PADDING / bandHeights);
    });

    const x = scaleLinear().domain(domains.x).rangeRound([frame.left, frame.right]);

    return {
        frame,
        bands,
        x,
        height: totalHeight,
    };
};

const GroupedHorizontalPercentage = props => {
    const { visOptions, schoolData } = props;
    const ref = React.useRef();
    const dimensions = useComponentSize(ref);
    const domains = useDomains(schoolData, visOptions);
    const scales = useScales(dimensions, domains, visOptions);

    const chartProps = {
        ...props,
        dimensions,
        domains,
        scales,
    };

    let chartContent;
    let labelContent;
    if (scales) {
        chartContent = (
            <>
                <AxisGrid {...chartProps} />
                <Data {...chartProps} />
            </>
        );
        labelContent = <DomainLabels {...chartProps} />;
    }

    return (
        <div className={styles.container}>
            <svg
                className={styles.svg}
                ref={ref}
                height={scales && scales.height}
                style={{ height: scales && scales.height }}
            >
                {chartContent}
            </svg>
            {labelContent}
            {scales && <Legend {...chartProps} />}
        </div>
    );
};

const AxisGrid = props => {
    const { visOptions, localiser, scales, dimensions } = props;

    const xTicks = scales.x.ticks(5);

    // Lines protrude past the axis a bit.
    const linesStartY = scales.frame.top - 8;

    return (
        <g>
            <g>
                {xTicks.map((yVal, i) => (
                    <line
                        key={yVal}
                        className={"gridLine"}
                        y1={linesStartY}
                        y2={scales.frame.right}
                        x1={scales.x(yVal)}
                        x2={scales.x(yVal)}
                        data-bottom={i === 0}
                    />
                ))}
            </g>
            <g>
                {xTicks.map(yVal => (
                    <text key={yVal} className={"gridLabel"} x={scales.x(yVal)} y={linesStartY - 8} data-align="start">
                        {yVal}%
                    </text>
                ))}
            </g>
            <g>
                <line
                    className={"axisLine"}
                    x1={scales.frame.left}
                    x2={scales.frame.right + 36}
                    y1={scales.frame.top}
                    y2={scales.frame.top}
                />
                <text className={"axisText"} x={dimensions.width} y={16} data-align="end">
                    {localiser(visOptions.xAxisLabel || "Percentage of students")}
                </text>
            </g>
        </g>
    );
};

const DomainLabels = props => {
    const { domains, localiser, scales } = props;

    const groupLabels = domains.groupBands.map((group, groupIndex) => {
        const yStart = scales.bands[groupIndex].range()[0] - 12;
        return (
            <div
                key={group}
                className={styles.domainLabel}
                data-group
                style={{
                    top: yStart,
                    maxWidth: PARA_WIDTH,
                }}
            >
                {localiser(group)}
            </div>
        );
    });

    const domainLabels = domains.domains.map((group, groupIndex) => {
        return group.map(domain => {
            const yStart = scales.bands[groupIndex](domain);
            const yMid = yStart + scales.bands[groupIndex].bandwidth() / 2;
            return (
                <div
                    key={domain}
                    className={styles.domainLabel}
                    style={{
                        top: yMid,
                        maxWidth: PARA_WIDTH,
                    }}
                >
                    {localiser(domain)}
                </div>
            );
        });
    });

    return (
        <div className={styles.labelContainer}>
            {groupLabels}
            {domainLabels}
        </div>
    );
};

const Data = props => {
    return (
        <g>
            {props.visOptions.groups.map((group, groupIndex) => {
                return group.domains.map(domain => {
                    return <DomainData key={domain.label} {...props} groupIndex={groupIndex} domain={domain} />;
                });
            })}
        </g>
    );
};

const DomainData = props => {
    const { domain, groupIndex, schoolData, scales, visOptions } = props;
    const yStart = scales.bands[groupIndex](domain.label);
    const y = yStart + scales.bands[groupIndex].bandwidth() / 2;

    const datumOrder = visOptions.showOECD !== undefined && !visOptions.showOECD ? ["school", "country"] : DATUM_ORDER;

    const symbols = datumOrder.map(datumName => {
        const v = domain.columns[datumName];
        const value = schoolData[v.value || v];
        const significant = !v.significant || significanceColumn(schoolData, v.significant);

        const x = scales.x(value);

        if (datumName === "school") {
            const yOffset = -1;
            return (
                <HexagonPolygonSmall
                    key={datumName}
                    className={styles.chartDatum}
                    transform={`translate(${x - SCHOOL_RADIUS} ${y - SCHOOL_RADIUS + yOffset})`}
                    data-significant={significant}
                    data-datum={datumName}
                    style={{
                        transformOrigin: `${x}px ${y + yOffset}px`,
                    }}
                />
            );
        } else if (datumName === "country") {
            return (
                <rect
                    key={datumName}
                    className={styles.chartDatum}
                    data-significant={significant}
                    data-datum={datumName}
                    x={x - DIAMOND_WIDTH}
                    y={y - DIAMOND_WIDTH}
                    width={2 * DIAMOND_WIDTH}
                    height={2 * DIAMOND_WIDTH}
                    style={{
                        transformOrigin: `${x}px ${y}px`,
                        transform: "rotate(45deg)",
                    }}
                />
            );
        } else {
            return (
                <circle
                    key={datumName}
                    className={styles.chartDatum}
                    cx={x}
                    cy={y}
                    r={CIRCLE_RADIUS}
                    data-significant={significant}
                    data-datum={datumName}
                />
            );
        }
    });

    const fillEndAt = scales.x(schoolData[domain.columns.school]);
    const fillWidth = fillEndAt - scales.frame.left;

    const fillRect = (
        <rect
            className={styles.schoolFillRect}
            x={scales.frame.left}
            y={y - BAND_HEIGHT / 2}
            width={fillWidth}
            height={BAND_HEIGHT}
        />
    );

    return (
        <g>
            {fillRect}
            {symbols}
        </g>
    );
};

const Legend = props => {
    const { localiser, visOptions, batchConfig } = props;

    return (
        <div className={"legend"} style={{ paddingLeft: padding.left }}>
            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <HexagonSvg className={"legendIcon"} data-school />
                </div>
                <span className={"legendLabel"}>{props.yourSchool}</span>
            </div>
            <div className={"legendItem"}>
                <div className={"legendIconGroup"} style={{ height: 18.4 }}>
                    <div className={styles.legendSquare} data-rotate data-datum="country" />
                    <div className={styles.legendSquare} data-rotate data-datum="country" data-significant="true" />
                </div>
                <span className={"legendLabel"}>
                    {visOptions.quintiles ? localiser("Lowest-performing students") : 
                    (visOptions.showPBTSCountry === true) && (batchConfig.countryNamePbts)} 
                    {(visOptions.showPBTSCountry !== true) && (batchConfig.countryName)}

                </span>
            </div>
            {(visOptions.showOECD === undefined || visOptions.showOECD !== false) && (
                <div className={"legendItem"}>
                    <div className={"legendIconGroup"}>
                        <div className={styles.legendCircle} data-datum="oecd" />
                        <div className={styles.legendCircle} data-datum="oecd" data-significant="true" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser(visOptions.quintiles ? "Highest-performing students" : "OECD")}
                    </span>
                </div>
            )}
        </div>
    );
};

export default GroupedHorizontalPercentage;
