import React from "react";

import useComponentSize from "../../modules/hooks/use-component-size";
import { scaleLinear } from "d3-scale";
import { extent } from "d3-array";
import DomainBadges from "../../components/domain-badges";
import styles from "./scatterplot.module.scss";
import { HexagonPolygonSmall, BigArrow, HexagonSvg } from "../../components/shapes";
import { formatDecimal, formatBreaks } from "../../modules/format";

const padding = {
    top: 80,
    bottom: 68,
    left: 44,
    right: 32,
};

const useDomains = (pisaData, visOptions) => {
    const x = [-3, 3];
    const y = [250, 750];

    const escs = extent(pisaData.schools, d => d.escs);
    const radius = extent(pisaData.schools, d => d.samples);

    return {
        x,
        y,
        radius,
        escs,
    };
};

const useScales = (dimensions, domains, visOptions) => {
    if (!dimensions) return undefined;

    const frame = {
        top: padding.top,
        right: dimensions.width - padding.right,
        bottom: dimensions.height - padding.bottom,
        left: padding.left,
    };

    const x = scaleLinear().domain(domains.x).rangeRound([frame.left, frame.right]);

    const y = scaleLinear().domain(domains.y).rangeRound([frame.bottom, frame.top]);

    const radius = scaleLinear().domain(domains.radius).rangeRound([3, 8]);

    return {
        frame,
        x,
        y,
        radius,
    };
};

// NOTE: revisit "trendKey" - it may use cols from schoolData
const Scatterplot = props => {
    const { visOptions, pisaData } = props;
    const ref = React.useRef();
    const dimensions = useComponentSize(ref);
    const domains = useDomains(pisaData, visOptions);
    const scales = useScales(dimensions, domains, visOptions);

    const chartProps = {
        ...props,
        dimensions,
        domains,
        scales,
    };

    let chartContent;
    if (scales) {
        chartContent = (
            <>
                <AxisGrid {...chartProps} />
                <SignificantIntervals {...chartProps} />
                <Data {...chartProps} />
            </>
        );
    }
    let overlayContent;
    if (scales) {
        overlayContent = <LabelOverlay {...chartProps} />;
    }

    return (
        <div>
            <div className={styles.container}>
                <svg className={styles.svg} ref={ref}>
                    {chartContent}
                </svg>
                {overlayContent}
            </div>
            <Legend {...chartProps} />
        </div>
    );
};

const AxisGrid = props => {
    const { visOptions, localiser, scales } = props;

    const yTicks = scales.y.ticks(8).filter(d => d % 100 !== 0);
    const xTicks = scales.x.ticks(7);

    // Lines protrude past the axis a bit.
    const linesStartX = scales.frame.left - 8;
    const linesStartY = scales.frame.bottom + 8;

    return (
        <g>
            <g>
                <g>
                    {yTicks.map((yVal, i) => (
                        <line
                            key={yVal}
                            className={"gridLine"}
                            x1={linesStartX}
                            x2={scales.frame.right}
                            y1={scales.y(yVal)}
                            y2={scales.y(yVal)}
                            data-bottom={i === 0}
                        />
                    ))}
                </g>
                <g>
                    {yTicks.map(yVal => (
                        <text key={yVal} className={"gridLabel"} x={linesStartX - 8} y={scales.y(yVal)}>
                            {yVal}
                        </text>
                    ))}
                </g>
            </g>
            <g>
                <g>
                    {xTicks.map((xVal, i) => (
                        <line
                            key={xVal}
                            className={"gridLine"}
                            x1={scales.x(xVal)}
                            x2={scales.x(xVal)}
                            y1={linesStartY}
                            y2={scales.frame.top}
                            data-bottom={i === 0}
                        />
                    ))}
                </g>
                <g>
                    {xTicks.map(xVal => (
                        <text
                            key={xVal}
                            className={"gridLabel"}
                            x={scales.x(xVal)}
                            y={linesStartY + 24}
                            data-align={xVal < 0 ? "end" : xVal > 0 ? "start" : "middle"}
                        >
                            {xVal === 0 ? "0" : formatDecimal(xVal, localiser("language-tag", 2))}
                        </text>
                    ))}
                </g>
            </g>
            <text className={"axisText"} x={0} y={scales.frame.top - 16 - 8} data-align="start">
                {localiser(visOptions.yAxisLabel || "Missing yAxisLabel")}
            </text>
            <g>
                <g transform={`translate(${scales.frame.left} ${scales.frame.bottom + 50})`}>
                    <BigArrow />
                    <text className={"gridLabel"} x={54} y={14} data-align={"start"}>
                        {localiser("Disadvantage")}
                    </text>
                </g>
                <g transform={`translate(${scales.frame.right} ${scales.frame.bottom + 50})`}>
                    <BigArrow transform={`translate(0 19) rotate(180)`} />
                    <text className={"gridLabel"} x={-54} y={14}>
                        {localiser("Advantage")}
                    </text>
                </g>
            </g>
        </g>
    );
};

const Data = props => {
    const { scales, schoolData, pisaData, visOptions } = props;

    const pisaPoints = pisaData.schools.map(datum => {
        const x = scales.x(datum.escs);
        const y = scales.y(datum[visOptions.pisaKey]);
        const r = scales.radius(datum.samples);
        return <circle key={datum.schoolId} className={styles.pisaPoint} cx={x} cy={y} r={r} />;
    });

    const schoolVal = schoolData[visOptions.valueKey];
    const schoolEscs = schoolData.ESCS;
    const schoolX = scales.x(schoolEscs);
    const schoolY = scales.y(schoolVal);

    const schoolPoint = (
        <HexagonPolygonSmall
            className={styles.schoolPoint}
            transform={`translate(${schoolX} ${schoolY}) translate(-8 -9)`}
        />
    );

    return (
        <g>
            <g>{pisaPoints}</g>
            <TrendLine {...props} />
            {schoolPoint}
        </g>
    );
};

const trendLineY = (x, slope, intercept) => intercept + slope * x;

const TrendLine = props => {
    const { scales, domains, pisaData, visOptions } = props;

    const { slope, intercept } = pisaData.trends[visOptions.trendKey];

    const startX = domains.escs[0];
    const startY = trendLineY(startX, slope, intercept);

    const endX = domains.escs[1];
    const endY = trendLineY(endX, slope, intercept);

    return (
        <g>
            <line
                className={styles.trendLine}
                x1={scales.x(startX)}
                y1={scales.y(startY)}
                x2={scales.x(endX)}
                y2={scales.y(endY)}
            />
        </g>
    );
};

const SignificantIntervals = props => {
    const { scales, localiser, schoolData, visOptions, batchConfig } = props;

    const scoreIntervalValues = visOptions.scoreInterval.map(d => schoolData[d]);
    const scoreIntervalPos = scoreIntervalValues.map(d => scales.y(d));

    const scoreInterval = (
        <g>
            <rect
                className={"confidenceIntervalRect"}
                x={scales.frame.left}
                width={scales.frame.right - scales.frame.left}
                y={scoreIntervalPos[1]}
                height={scoreIntervalPos[0] - scoreIntervalPos[1]}
            />
            <line
                className={"confidenceIntervalLine"}
                x1={scales.frame.left}
                x2={scales.frame.right}
                y1={scoreIntervalPos[1]}
                y2={scoreIntervalPos[1]}
            />
            <line
                className={"confidenceIntervalLine"}
                x1={scales.frame.left}
                x2={scales.frame.right}
                y1={scoreIntervalPos[0]}
                y2={scoreIntervalPos[0]}
            />
        </g>
    );

    const escsIntervalValues = visOptions.escsInterval.map(d => schoolData[d]);
    const escsIntervalPos = escsIntervalValues.map(d => scales.x(d));

    const escsInterval = (
        <g>
            <rect
                className={"confidenceIntervalRect"}
                data-grey={true}
                x={escsIntervalPos[0]}
                width={escsIntervalPos[1] - escsIntervalPos[0]}
                y={scales.frame.top}
                height={scales.frame.bottom - scales.frame.top}
            />
            <line
                className={"confidenceIntervalLine"}
                data-grey={true}
                y1={scales.frame.top}
                y2={scales.frame.bottom}
                x1={escsIntervalPos[1]}
                x2={escsIntervalPos[1]}
            />
            <line
                className={"confidenceIntervalLine"}
                data-grey={true}
                y1={scales.frame.top}
                y2={scales.frame.bottom}
                x1={escsIntervalPos[0]}
                x2={escsIntervalPos[0]}
            />
        </g>
    );

    [
        "95% confidence interval for Your|School's performance",
        "95% confidence interval for Your|Region's performance",
    ].forEach(localiser);

    const confidenceText = batchConfig.groupReport
        ? "95% confidence interval for Your|Region's performance"
        : "95% confidence interval for Your|School's performance";

    const scoreLeft = batchConfig.scatterAlign === 'right' ? scales.frame.right -  220 : scales.frame.left + 24;
    const scoreY = (scoreIntervalPos[0] + scoreIntervalPos[1]) / 2;
    const scoreExplainer = (
        <text x={scoreLeft} y={scoreY} className={"confidenceIntervalLabel"}>
            <tspan dy={-2} x={scoreLeft}>
                {localiser(confidenceText).split("|")[0]}
            </tspan>
            <tspan dy={16} x={scoreLeft}>
                {localiser(confidenceText).split("|")[1]}
            </tspan>
        </text>
    );

    const escsLeft = (escsIntervalPos[0] + escsIntervalPos[1]) / 2;

    [
        "Schools with a socio-economic profile|similar to that of Your School",
        "Schools with a socio-economic profile|similar to that of Your Region",
    ].forEach(localiser);

    const explainerString = batchConfig.groupReport
        ? "Schools with a socio-economic profile|similar to that of Your Region"
        : "Schools with a socio-economic profile|similar to that of Your School";

    const escsExplainer = (
        <text className={styles.escsIntervalLabel} x={0} y={0} data-align="middle">
            <tspan dy={16} x={escsLeft}>
                {localiser(explainerString).split("|")[0]}
            </tspan>
            <tspan dy={18} x={escsLeft}>
                {localiser(explainerString).split("|")[1]}
            </tspan>
        </text>
    );

    return (
        <g>
            {escsInterval}
            {scoreInterval}
            {scoreExplainer}
            {escsExplainer}
        </g>
    );
};

const LabelOverlay = props => {
    const { scales, visOptions, localiser } = props;
    const labelPadding = 40;

    const Badge = DomainBadges[visOptions.domain];

    const abovePara = localiser(
        "Schools well|above the diagonal line perform better|than what would be reasonably expected given the socio-economic status of their students."
    ).split("|");

    const belowPara = localiser(
        "Schools well|below the diagonal line perform lower|than what would be reasonably expected given the socio-economic status of their students."
    ).split("|");

    return (
        <div className={"labelOverlay"}>
            <p
                className={styles.overlayLabel}
                style={{
                    top: scales.frame.top + labelPadding,
                    left: scales.frame.left + labelPadding,
                }}
                // data-align="right"
                // data-horizontalalign="right"                
            >
                {abovePara[0]} <b>{abovePara[1]}</b> {abovePara[2]}
            </p>
            <p
                className={styles.overlayLabel}
                style={{
                    top: scales.frame.bottom - labelPadding,
                    left: scales.frame.right - labelPadding,
                }}
                data-verticalalign="bottom"
                data-align="right"
            >
                {belowPara[0]} <b>{belowPara[1]}</b> {belowPara[2]}
            </p>
            <div
                className={styles.badgeArea}
                style={{
                    left: scales.frame.left + 46,
                    bottom: padding.bottom + 60,
                }}
            >
                <Badge />
                <span className={styles.badgeDomain}>{localiser(visOptions.domain)}</span>
            </div>
            <p
                className={styles.axisLabel}
                style={{
                    left: scales.frame.left + scales.frame.right / 2,
                    top: scales.frame.bottom + 91,
                }}
                data-align="middle"
            >
                {formatBreaks(
                    localiser(visOptions.xAxisLabel || "PISA index of economic, social and cultural status (ESCS)")
                )}
            </p>
        </div>
    );
};

const Legend = props => {
    const { batchConfig, localiser } = props;
    return (
        <div className={"legend"} style={{ paddingLeft: padding.left }}>
            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <HexagonSvg className={"legendIcon"} data-school />
                </div>
                <span className={"legendLabel"}>{props.yourSchool}</span>
            </div>

            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <div className={styles.legendSchoolIcon} />
                </div>
                <span className={"legendLabel"}>
                    {localiser("Schools in <Country> that participated in PISA {PISA Year}").replace(
                        "{PISA Year}",
                        batchConfig.pisaYear
                    )}
                </span>
            </div>

            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <div className={styles.legendRegressionLine} />
                </div>
                <span className={"legendLabel"}>{localiser("Regression line")}</span>
            </div>
        </div>
    );
};

export default Scatterplot;
