import React from "react";

export const HexagonPolygon = props => (
    <path
        {...props}
        d="M23.5076684,6.63754709 C23.1755733,6.0636305 22.6987797,5.58683699 22.1248632,5.25474179 L13.8888748,0.506188393 C12.7190036,-0.168578973 11.2781563,-0.168578973 10.1082851,0.506188393 L1.89165601,5.25474179 C1.33222835,5.57559149 0.86537221,6.03577826 0.536506821,6.59053171 C0.186094267,7.17553732 0.000686236429,7.84453537 -2.99061963e-05,8.52645913 L-2.99061963e-05,18.0125035 C-0.00284766658,19.3665684 0.7187113,20.6187475 1.89165601,21.2952833 L10.1082851,26.0327742 C11.2781563,26.7075416 12.7190036,26.7075416 13.8888748,26.0327742 L22.1055039,21.2952833 C23.2777981,20.6204372 24.0002227,19.3706954 23.9999701,18.0180347 L23.9999701,8.52645913 C24.002241,7.86481015 23.8326078,7.21391423 23.5076684,6.63754709"
    ></path>
);

export const HexagonPolygonSmall = props => (
    <path
        {...props}
        d="M15.671769,4.42506902 C15.4503722,4.04245796 15.1325099,3.72459561 14.7498988,3.50319882 L9.25923989,0.337496552 C8.47932578,-0.112348359 7.51876088,-0.112348359 6.73884677,0.337496552 L1.26109404,3.50319882 C0.888142266,3.71709861 0.576904838,4.02388979 0.357661245,4.39372543 C0.124052876,4.78372917 0.000447522221,5.22972787 -2.99061963e-05,5.68434371 L-2.99061963e-05,12.0083733 C-0.00190841312,12.9110832 0.479130898,13.7458693 1.26109404,14.1968931 L6.73884677,17.3552204 C7.51876088,17.8050653 8.47932578,17.8050653 9.25923989,17.3552204 L14.7369926,14.1968931 C15.5185221,13.7469957 16.0001385,12.9138346 15.9999701,12.0120608 L15.9999701,5.68434371 C16.001484,5.24324439 15.8883952,4.80931377 15.671769,4.42506902"
    ></path>
);

export const HexagonSvg = props => (
    <svg width="24px" height="27px" viewBox="-1 -1 26 29" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g stroke="#005441" fill="#00AE88" fillRule="nonzero">
            <HexagonPolygon />
        </g>
    </svg>
);

export const SquarePolygonSmall = props => <rect x="0.5" y="0.5" width="11" height="11" {...props} />;
export const SquarePolygon = props => <rect x="0.5" y="0.5" width="15" height="15" {...props} />;

export const SquareSvg = props => (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <SquarePolygon />
    </svg>
);

export const DiamondPolygonSmall = props => (
    <g fill="#777777" stroke="#333333" {...props}>
        <rect
            transform="translate(7.000000, 7.000000) rotate(45.000000) translate(-7.000000, -7.000000)"
            x="4"
            y="4"
            width="11"
            height="11"
        ></rect>
    </g>
);
export const DiamondPolygon = props => (
    <g fill="#777777" stroke="#333333" {...props}>
        <rect
            transform="translate(11.000000, 11.000000) rotate(45.000000) translate(-11.000000, -11.000000)"
            x="4"
            y="4"
            width="15"
            height="15"
        ></rect>
    </g>
);

export const DiamondSvg = props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <DiamondPolygon />
    </svg>
);

export const OecdPolygon = props => (
    <circle id="Oval" cx="12" cy="12" r="11.5" fill="#04629a" stroke="#004b78" {...props} />
);

export const OecdSvg = props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <OecdPolygon />
    </svg>
);

export const Arrow = props => (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" {...props}>
        <g transform="translate(-55.000000, -40.000000)" fill="#000000" fillRule="nonzero">
            <path d="M60.5,42 L60.5,60.591 L64.0642122,54.2548694 L64.9357878,54.7451306 L60.4357878,62.7451306 L60,63.5198644 L59.5642122,62.7451306 L55.0642122,54.7451306 L55.9357878,54.2548694 L59.5,60.591 L59.5,42 L60.5,42 Z"></path>
        </g>
    </g>
);

export const BigArrow = props => (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" {...props}>
        <g transform="translate(-30.000000, -15.000000)" fill="#979797" fillRule="nonzero">
            <path d="M44.6313143,15.8881632 L45.6118368,17.6313143 L44.7402612,18.1215755 L36.067,23 L74,23 L74,25 L36.067,25 L44.7402612,29.8784245 L45.6118368,30.3686857 L44.6313143,32.1118368 L43.7597388,31.6215755 L31.7597388,24.8715755 L30.2102711,24 L31.7597388,23.1284245 L43.7597388,16.3784245 L44.6313143,15.8881632 Z"></path>
        </g>
    </g>
);
