import React from "react";

export const Mathematics = props => (
    <svg width="54px" height="62px" viewBox="0 0 54 62" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
                fill="#00AE88"
                fillRule="evenodd"
                points="27 0 53.8467875 15.5 53.8467875 46.5 27 62 0.153212483 46.5 0.153212483 15.5"
            />
            <path
                d="M21.428868,21.7258502 L21.428868,29.1796264 L21.428868,21.7258502 Z M17.7154718,25.4517005 L25.1422641,25.4517005 L17.7154718,25.4517005 Z M35.3587738,30.1095322 L29.785566,35.6993454 L35.3587738,30.1095322 Z M29.785566,30.1095322 L35.3587738,35.6993454 L29.785566,30.1095322 Z M18.6433019,38.4952898 L25.1422641,38.4952898 L18.6433019,38.4952898 Z M21.8938209,34.7694396 L21.8938209,34.7694396 C22.1491298,34.7694396 22.3566981,34.9790835 22.3566981,35.2343925 L21.8938209,34.7694396 Z M21.428868,35.2343925 L21.428868,35.2343925 C21.428868,34.9790835 21.6364362,34.7694396 21.8938209,34.7694396 L21.428868,35.2343925 Z M21.8938209,35.6993454 L21.8938209,35.6993454 C21.6364362,35.701421 21.428868,35.4938528 21.428868,35.2364681 L21.428868,35.2343925 L21.8938209,35.6993454 Z M22.3566981,35.2343925 L22.3566981,35.2343925 C22.3566981,35.4917771 22.1512055,35.6993454 21.8938209,35.6993454 L21.8938209,35.6993454 L22.3566981,35.2343925 Z M21.8938209,41.2891586 L21.8938209,41.2891586 C22.1491298,41.2891586 22.3566981,41.4988025 22.3566981,41.7561871 L21.8938209,41.2891586 Z M21.428868,41.7561871 L21.428868,41.7561871 C21.428868,41.4988025 21.6364362,41.2912342 21.8938209,41.2912342 L21.428868,41.7561871 Z M21.8938209,42.2232157 L21.8938209,42.2232157 C21.6364362,42.2232157 21.428868,42.0156475 21.428868,41.7582628 L21.428868,41.7561871 L21.8938209,42.2232157 Z M22.3566981,41.7561871 L22.3566981,41.7561871 C22.3587738,42.0135718 22.1512055,42.22114 21.8958965,42.2232157 L21.8938209,42.2232157 L22.3566981,41.7561871 Z M40,44.085103 L40,44.085103 C40,45.1146415 39.169727,45.9490659 38.1443398,45.9490659 L15.8577359,45.9490659 C14.8323487,45.9490659 14,45.1146415 14,44.085103 L14,19.863963 C14,18.8344244 14.8323487,18 15.8577359,18 L34.474533,18 C34.958167,18 35.4251956,18.1909628 35.7718346,18.5313747 L39.4395657,22.1098515 C39.7986588,22.4606419 40,22.9422002 40,23.4445154 L40,44.085103 Z"
                stroke="#FFFFFF"
                strokeWidth="1.984"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export const Reading = props => (
    <svg width="54px" height="62px" viewBox="0 0 54 62" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
                fill="#00AE88"
                fillRule="evenodd"
                points="27 0 53.8467875 15.5 53.8467875 46.5 27 62 0.153212483 46.5 0.153212483 15.5"
            />
            <path
                d="M27,44 L27,23.3619066 C27,23.3619066 24.1632595,20.1291947 14.5986744,20 C14.4403267,19.9980583 14.2858412,20.0616396 14.1757703,20.1768807 C14.0618372,20.2941087 14,20.4510751 14,20.6140022 L14,40.0221948 C13.9961809,40.3540097 14.253013,40.628204 14.5774326,40.6381386 C24.1613285,40.771262 27,44 27,44 M23.533731,31.0274274 L23.533731,31.0274274 C21.5659716,30.3498891 19.5248316,29.928663 17.4547256,29.7776574 M23.533731,36.0006774 L23.533731,36.0006774 C21.5659716,35.3211522 19.5248316,34.901913 17.4547256,34.7489205 M30.466269,31.0274274 L30.466269,31.0274274 C32.4340284,30.3498891 34.4751684,29.928663 36.5452744,29.7776574 M30.466269,36.0006774 L30.466269,36.0006774 C32.4340284,35.3211522 34.4751684,34.901913 36.5452744,34.7489205 M27,44 L27,23.3619066 C27,23.3619066 29.8367405,20.1291947 39.4013256,20 C39.5596733,19.9980583 39.7122277,20.0616396 39.8242297,20.1768807 C39.9381628,20.2941087 40,20.4510751 40,20.6140022 L40,40.0221948 C40.0038191,40.3540097 39.746987,40.628204 39.4225674,40.6381386 C29.8386715,40.771262 27,44 27,44"
                stroke="#FFFFFF"
                strokeWidth="1.984"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export const Science = props => (
    <svg width="54px" height="62px" viewBox="0 0 54 62" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <polygon
            fill="#00AE88"
            fillRule="evenodd"
            points="27 0 53.8467875 15.5 53.8467875 46.5 27 62 0.153212483 46.5 0.153212483 15.5"
        />
        <path
            fill="transparent"
            d="M28.8398784,29.159404 C29.8555811,30.1751068 29.8555811,31.8241756 28.8398784,32.8398784 C27.8241756,33.8555811 26.1751068,33.8555811 25.159404,32.8398784 C24.1437012,31.8241756 24.1437012,30.1751068 25.159404,29.159404 C26.1751068,28.1437012 27.8241756,28.1437012 28.8398784,29.159404 M32.6381433,19.2900943 C39.1050418,16.1754012 41.8238812,18.8942406 38.7111191,25.3611391 C35.5964259,31.8280376 27.8299686,39.5964259 21.3611391,42.7091881 C14.8942406,45.8238812 12.1754012,43.1050418 15.2900943,36.6381433 C18.4028564,30.1712448 26.1712448,22.4028564 32.6381433,19.2900943 M21.3611391,19.2900943 C27.8299686,22.4028564 35.5964259,30.1712448 38.7111191,36.6381433 C41.8238812,43.1050418 39.1050418,45.8238812 32.6381433,42.7091881 C26.1712448,39.5964259 18.4028564,31.8280376 15.2900943,25.3611391 C12.1754012,18.8942406 14.8942406,16.1754012 21.3611391,19.2900943"
            stroke="#FFFFFF"
            strokeWidth="1.984"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const DomainBadges = {
    Reading: Reading,
    Mathematics: Mathematics,
    Science: Science,
};

export default DomainBadges;
