import React from "react";
import { API_URL } from "../modules/fetch-data";

const SvgFlag = props => {
    const { maskId, params, ...rest } = props;
    const { year, countryCode, batchNumber } = params;
    const flagUrl = `${API_URL}/flag/${year}/${countryCode}/${batchNumber}.svg`;

    return <image xlinkHref={flagUrl} {...rest} />;
};

export default SvgFlag;
