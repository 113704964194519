import React from "react";
import useComponentSize from "../../modules/hooks/use-component-size";
import { scaleLinear, scaleBand } from "d3-scale";

import styles from "./baseline.module.scss";
import { formatBreaks } from "../../modules/format";

const padding = {
    top: 68,
    bottom: 0,
    left: 292,
    right: 34,
};

const BAND_PADDING = 13;
const BAR_HEIGHT = 16;
const DATUM_LABEL_LEFT = 180;

const useDomains = (schoolData, visOptions) => {
    const outerBands = visOptions.domains.map(d => d.label);
    const innerBands = Object.keys(visOptions.domains[0].scores);
    const x = [-100, 100];
    return {
        outerBands,
        innerBands,
        x,
    };
};

const useScales = (dimensions, domains, visOptions) => {
    if (!dimensions) return undefined;

    const frame = {
        top: padding.top,
        right: dimensions.width - padding.right,
        bottom: dimensions.height - padding.bottom,
        left: padding.left,
    };

    const innerHeight = dimensions.height - padding.top - padding.bottom;

    const outerBands = scaleBand()
        .domain(domains.outerBands)
        .rangeRound([frame.top, frame.bottom])
        .paddingOuter(0.25)
        .paddingInner(BAND_PADDING / innerHeight);

    const bandwidth = outerBands.bandwidth();

    const innerBands = scaleBand().domain(domains.innerBands).rangeRound([0, bandwidth]).paddingOuter(0);

    const x = scaleLinear().domain(domains.x).rangeRound([frame.left, frame.right]);

    return {
        frame,
        outerBands,
        innerBands,
        x,
        bandwidth,
    };
};

const Baseline = props => {
    const { visOptions, schoolData } = props;
    const ref = React.useRef();
    const dimensions = useComponentSize(ref);
    const domains = useDomains(schoolData, visOptions);
    const scales = useScales(dimensions, domains, visOptions);

    const chartProps = {
        ...props,
        dimensions,
        domains,
        scales,
    };

    let chartContent;
    let labelContent;
    if (scales) {
        chartContent = (
            <>
                <AxisGrid {...chartProps} />
                <Data {...chartProps} />
            </>
        );
        labelContent = <DomainLabels {...chartProps} />;
    }

    return (
        <div className={styles.container}>
            <svg className={styles.svg} ref={ref}>
                {chartContent}
            </svg>
            {labelContent}
            {scales && <Legend {...chartProps} />}
        </div>
    );
};

const AxisGrid = props => {
    const { visOptions, localiser, scales, dimensions } = props;

    const xTicks = scales.x.ticks(10);

    // Lines protrude past the axis a bit.
    const linesStartY = scales.frame.top - 8;

    return (
        <g>
            <g>
                {xTicks.map((yVal, i) => (
                    <line
                        key={yVal}
                        className={"gridLine"}
                        y1={linesStartY}
                        y2={scales.frame.right}
                        x1={scales.x(yVal)}
                        x2={scales.x(yVal)}
                        data-bottom={yVal === 0}
                    />
                ))}
            </g>
            <g>
                {xTicks.map(yVal => {
                    let align = "start";
                    if (yVal === 0) {
                        align = "middle";
                    }
                    if (yVal < 0) {
                        align = "end";
                    }
                    const label = Math.abs(yVal) + "%";
                    return (
                        <text
                            key={yVal}
                            className={"gridLabel"}
                            data-align={align}
                            x={scales.x(yVal)}
                            y={linesStartY - 8}
                        >
                            {label}
                        </text>
                    );
                })}
            </g>
            <g>
                <line
                    className={"axisLine"}
                    x1={scales.frame.left - 24}
                    x2={scales.frame.right + 36}
                    y1={scales.frame.top}
                    y2={scales.frame.top}
                />
                <text className={"axisText"} x={dimensions.width} y={16} data-align="end">
                    {localiser(visOptions.xAxisLabel) || "-"}
                </text>
            </g>
        </g>
    );
};

// TODO: localise all domains.
const DomainLabels = props => {
    const { visOptions, batchConfig, localiser, scales } = props;
    const yOffset = BAR_HEIGHT;
    const domainLabels = visOptions.domains.map(domain => {
        const yStart = scales.outerBands(domain.label) + yOffset;

        const datumLabels = Object.keys(domain.scores).map(datum => {
            const label =
                datum === "oecd" ? localiser("OECD") : datum === "country" ? batchConfig.countryName : props.yourSchool;

            const datumY = yStart + scales.innerBands(datum);
            return (
                <div
                    key={datum}
                    className={styles.datumLabel}
                    style={{
                        top: datumY,
                        left: DATUM_LABEL_LEFT,
                    }}
                >
                    {formatBreaks(label)}
                </div>
            );
        });

        return (
            <React.Fragment key={domain.label}>
                <div
                    key={domain.label}
                    className={styles.domainLabel}
                    style={{
                        top: yStart,
                        width: DATUM_LABEL_LEFT - 8,
                    }}
                >
                    {formatBreaks(localiser(domain.label))}
                </div>
                {datumLabels}
            </React.Fragment>
        );
    });

    return <div className={styles.labelContainer}>{domainLabels}</div>;
};

const Data = props => {
    return (
        <g>
            {props.visOptions.domains.map(d => (
                <DomainData key={d.label} {...props} domain={d} />
            ))}
        </g>
    );
};

const DomainData = props => {
    const { domain, schoolData, scales, visOptions } = props;

    const yStart = scales.outerBands(domain.label);

    const datums = Object.entries(domain.scores).map(([datumName, levelColumns]) => {
        const datumY = yStart + scales.innerBands(datumName);

        // We have to group BL1 and L1 into "less than 2" if not grouped.
        let lowValue = visOptions.grouped
            ? schoolData[levelColumns[0]] * -1
            : (schoolData[levelColumns[0][0]] + schoolData[levelColumns[0][1]]) * -1;

        if (isNaN(lowValue) && visOptions.grouped) {
            console.log("----- ----- Couldn't find " + levelColumns[0] + " in schoolData");
            console.log("----- ----- Not rendering DomainData in baseline.jsx");
            return null;
        } else {
            const lowStart = scales.x(lowValue);
            const lowEnd = scales.x(0);

            const lowBar = (
                <rect
                    className={styles.chartBar}
                    data-level={"-1"}
                    data-grouped={Boolean(visOptions.grouped)}
                    x={lowStart}
                    width={lowEnd - lowStart}
                    y={datumY}
                    height={BAR_HEIGHT}
                />
            );

            const rightSideBars = levelColumns.slice(1);
            const otherBars = rightSideBars.map((levelColumn, index) => {
                const prevValues = rightSideBars.slice(0, index);
                const prevValueSum = prevValues.reduce((acc, n) => acc + schoolData[n], 0);
                const nextSum = prevValueSum + schoolData[levelColumn];

                const barStart = scales.x(prevValueSum);
                const barEnd = scales.x(nextSum);

                return (
                    <rect
                        className={styles.chartBar}
                        key={index}
                        data-level={index}
                        data-grouped={Boolean(visOptions.grouped)}
                        x={barStart}
                        width={barEnd - barStart}
                        y={datumY}
                        height={BAR_HEIGHT}
                    />
                );
            });

            return (
                <g key={datumName} transform={`translate(0 ${BAR_HEIGHT})`}>
                    {lowBar}
                    {otherBars}
                </g>
            );
        }
    });

    return <g>{datums}</g>;
};

const Legend = props => {
    const { localiser, visOptions } = props;

    if (visOptions.grouped) {
        return (
            <div className={"legend"} data-closer style={{ paddingLeft: padding.left }} data-align="middle">
                <div className={"legendItem"} data-align="top">
                    <div>
                        <div className={styles.legendQuartileIcon} data-grouped data-level="-1" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser("Below baseline level|Below PISA Level 2").replace("|", "\n")}
                    </span>
                </div>
                <div className={"legendItem"} data-align="top">
                    <div>
                        <div className={styles.legendQuartileIcon} data-grouped data-level="0" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser("Intermediate levels|PISA Levels 2, 3 and 4").replace("|", "\n")}
                    </span>
                </div>
                <div className={"legendItem"} data-align="top">
                    <div>
                        <div className={styles.legendQuartileIcon} data-grouped data-level="1" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser("Top levels|PISA Levels 5 and 6").replace("|", "\n")}
                    </span>
                </div>
            </div>
        );
    } else {
        return (
            <div className={"legend"} data-closer style={{ paddingLeft: padding.left }} data-align="middle">
                <div className={"legendItem"} data-align="top">
                    <div>
                        <div className={styles.legendQuartileIcon} data-grouped="false" data-level="-1" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser("Below baseline level|Below PISA Level 2").replace("|", "\n")}
                    </span>
                </div>
                <div className={"legendItem"} data-align="top">
                    <div className="legendIconGroup">
                        <div className={styles.legendQuartileIcon} data-grouped="false" data-level="0" />
                        <div className={styles.legendQuartileIcon} data-grouped="false" data-level="1" />
                        <div className={styles.legendQuartileIcon} data-grouped="false" data-level="2" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser("Intermediate levels|PISA Levels 2, 3 and 4").replace("|", "\n")}
                    </span>
                </div>
                <div className={"legendItem"} data-align="top">
                    <div className="legendIconGroup">
                        <div className={styles.legendQuartileIcon} data-grouped="false" data-level="3" />
                        <div className={styles.legendQuartileIcon} data-grouped="false" data-level="4" />
                    </div>
                    <span className={"legendLabel"}>
                        {localiser("Top levels|PISA Levels 5 and 6").replace("|", "\n")}
                    </span>
                </div>
            </div>
        );
    }
};

export default Baseline;
