import React from "react";
import Flag from "../../components/flag";
import { HexagonSvg, OecdSvg } from "../../components/shapes";

import styles from "./vertical-performance-table.module.scss";

const VerticalPerformanceTable = props => {
    const { params, visOptions, localiser, batchConfig } = props;

    const domainRows = visOptions.domains.map(domain => <DomainRow key={domain.label} {...props} domain={domain} />);

    return (
        <div>
            <table className={styles.table}>
                <thead className={styles.headerRow}>
                    <tr>
                        <td />
                        <td className={styles.headerCell}>
                            <HexagonSvg />
                            <div>
                                {localiser(
                                    batchConfig.groupReport
                                        ? "Your Region's|score"
                                        : batchConfig.nationalReport
                                        ? "<PBTS Country's> Score"
                                        : "Your School's|score"
                                )
                                    .split("|")
                                    .map(d => (
                                        <div key={d} className={styles.headerText}>
                                            {d}
                                        </div>
                                    ))}
                            </div>
                        </td>
                        <td />
                        <td className={styles.headerCell}>
                            <Flag {...params} />
                            <div>
                                {localiser("<Country's>|score")
                                    .split("|")
                                    .map(d => (
                                        <div key={d} className={styles.headerText}>
                                            {d}
                                        </div>
                                    ))}
                            </div>
                        </td>
                        <td />
                        <td className={styles.headerCell}>
                            <OecdSvg /> {localiser("OECD's|score").replace("|", "\n")}
                        </td>
                    </tr>
                </thead>
                <tbody>{domainRows}</tbody>
            </table>
        </div>
    );
};

// Modified these functions to align with the Executive Summary

const getDirectionText = direction => {
    if (direction === -1) return "lower than";
    if (direction === 1) return "higher than";
    return "similar to";
};

const DomainRow = props => {
    const { domain, localiser, schoolData } = props;

    [
        "higher than",
        "lower than",
        "similar to",
        "which is",
        "which is significantly",
        "and is",
        "and significantly",
    ].forEach(localiser);

    const school = schoolData[domain.columns.school];
    const country = schoolData[domain.columns.country];
    const oecd = schoolData[domain.columns.oecd];

    const countrySig = schoolData[domain.columns.countrySig];
    const oecdSig = schoolData[domain.columns.oecdSig];

    const countryDistance = countrySig;
    const oecdDistance = oecdSig;

    return (
        <tr className={styles.bodyRow}>
            <td className={styles.domainLabelCell}>{localiser(domain.label)}</td>
            <td className={styles.scoreCell}>{Math.round(school)}</td>
            <td className={styles.comparisonTextCell}>
                {localiser(countryDistance === 0 ? "which is" : "which is significantly")}
                <br />
                <b>{localiser(getDirectionText(countryDistance))}</b>
            </td>
            <td className={styles.scoreCell}>{Math.round(country)}</td>
            <td className={styles.comparisonTextCell}>
                {localiser(oecdDistance === 0 ? "and is" : "and significantly")}
                <br />
                <b>{localiser(getDirectionText(oecdDistance))}</b>
            </td>
            <td className={styles.scoreCell}>{Math.round(oecd)}</td>
        </tr>
    );
};

export default VerticalPerformanceTable;
