import React from "react";
import styles from "./executive-summary.module.scss";
import Flag from "../../components/flag";
import { Reading, Mathematics, Science } from "../../components/domain-badges";
import { significanceColumn } from "../../modules/significance";
import { scaleLinear } from "d3-scale";
import { ReactComponent as OECDLogo } from "./assets/OECD_20cm_4c.svg";

function ExecutiveSummary(props) {
    const { localiser } = props;

    return (
        <div className={styles.executiveSummary}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td />
                        <td>
                            <div>
                                <Reading />
                                <span className={styles.columnHeading}>{localiser("Reading")}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <Mathematics />
                                <span className={styles.columnHeading}>{localiser("Mathematics")}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <Science />
                                <span className={styles.columnHeading}>{localiser("Science")}</span>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <PerformanceAverageRow {...props} />
                    <GenderDifferenceRow {...props} />
                    <SocioeconomicDifferenceRow {...props} />
                    <EngagementRow {...props} border={"topBorderThick"} />
                    <SocialRow {...props} border="bottomBorderThick" />
                </tbody>
            </table>
        </div>
    );
}

function ExecutiveSummaryCovid(props) {
    const { localiser } = props;

    return (
        <div className={styles.executiveSummary}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <td />
                        <td>
                            <div>
                                <Reading />
                                <span className={styles.columnHeading}>{localiser("Reading")}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <Mathematics />
                                <span className={styles.columnHeading}>{localiser("Mathematics")}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <Science />
                                <span className={styles.columnHeading}>{localiser("Science")}</span>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <PerformanceAverageRow {...props} />
                    <GenderDifferenceRow {...props} />
                    <CovidRow {...props} border={"topBorderThick"} />
                    <EngagementRow {...props} />
                    <ResilienceRow {...props} border={"bottomBorderThick"} />
                </tbody>
            </table>
        </div>
    );
}

function PerformanceAverageRow(props) {
    const { localiser, batchConfig } = props;

    [
        "Average performance|of Your Region",
        "Average performance|of <PBTS Country>",
        "Average performance|of Your School",
    ].forEach(localiser);

    const titleLocalised = localiser(
        batchConfig.groupReport
            ? "Average performance|of Your Region"
            : batchConfig.nationalReport
            ? "Average performance|of <PBTS Country>"
            : "Average performance|of Your School"
    );
    const titleSplit = titleLocalised.split("|");
    const titledHighlighted = titleSplit[0];
    const titleLeftover = titleSplit[1];

    return (
        <tr>
            <td className={styles.rowHeadingCell}>
                <div className={styles.rowHeading}>
                    <span className={styles.highlight}>{titledHighlighted}</span>
                    {titledHighlighted && (
                        <span>
                            <br />
                            {titleLeftover}
                        </span>
                    )}
                </div>
            </td>
            <PerformanceAverageCell {...props} scorePrefix="readscore" sigSuffix={"read"} refPrefix={"readscore"} />
            <PerformanceAverageCell {...props} scorePrefix="mathscore" sigSuffix={"math"} refPrefix={"mathscore"} />
            <PerformanceAverageCell {...props} scorePrefix="sciscore" sigSuffix={"scie"} refPrefix={"sciescore"} />
        </tr>
    );
}

function PerformanceAverageCell(props) {
    const { schoolData, scorePrefix, localiser, sigSuffix, refPrefix } = props;

    const school = Math.round(schoolData[scorePrefix]);
    const country = schoolData[`${refPrefix}_ref_cty`];
    const oecd = schoolData[`${refPrefix}_ref_oecd`];
    const performanceDiffSignificant = schoolData[`sig_${sigSuffix}`];

    let dir = "similar";
    if (performanceDiffSignificant === -1) dir = "lower";
    if (performanceDiffSignificant === 1) dir = "higher";

    ["similar", "higher", "lower", "to Your Country", "than Your Country"].forEach(localiser);

    return (
        <td>
            <div className={styles.cellCenter}>
                <span className={styles.rowScore}>{school}</span>
                <div>
                    <span>
                        {localiser("which is")} <span className={styles.rowComparison}>{localiser(dir)}</span>
                        <br />
                        {localiser(dir === "similar" ? "to Your Country" : "than Your Country")}
                    </span>
                    <Flags countryScore={country} oecdScore={oecd} params={props.params} />
                </div>
            </div>
        </td>
    );
}

function GenderDifferenceRow(props) {
    const { localiser } = props;

    return (
        <tr>
            <td className={styles.rowHeadingCell}>
                <div className={styles.rowHeading}>
                    <span className={styles.highlight}>
                        {localiser("Gender|differences in performance").split("|")[0]}
                    </span>{" "}
                    {localiser("Gender|differences in performance").split("|")[1]}
                </div>
            </td>
            <GenderDifferenceCell {...props} scorePrefix="readscore" refPrefix={"readscore"} sigSuffix="read" />
            <GenderDifferenceCell {...props} scorePrefix="mathscore" refPrefix={"mathscore"} sigSuffix="math" />
            <GenderDifferenceCell {...props} scorePrefix="sciscore" refPrefix={"sciescore"} sigSuffix="scie" />
        </tr>
    );
}

const MaleIcon = () => (
    <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g stroke="#000000" strokeWidth="3">
                <path d="M19.6506747,15.3648864 L33,2 L19.6506747,15.3648864 Z M5.02998501,15.3588896 L5.02998501,15.3588896 C9.06996502,11.3250367 15.6186907,11.3250367 19.6566717,15.3608886 C23.6966517,19.3947415 23.6966517,25.9372592 19.6566717,29.9731111 C15.6166917,34.008963 9.06796602,34.008963 5.02998501,29.9731111 C0.990004998,25.9372592 0.990004998,19.3947415 5.02998501,15.3608886 C5.02998501,15.3588896 5.02998501,15.3588896 5.02998501,15.3588896 Z M23.6906547,2 L32.998001,2 L32.998001,11.3010494"></path>
            </g>
        </g>
    </svg>
);

const FemaleIcon = () => (
    <svg width="24px" height="35px" viewBox="0 0 24 35" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(-124.000000, -39.000000)" stroke="#000000" strokeWidth="3">
                <path d="M136,61.667333 L136,72 L136,61.667333 Z M131.998823,67.8681326 L140.001177,67.8681326 L131.998823,67.8681326 Z M136,41 C141.52394,41 146,45.6276116 146,51.334666 C146,57.0417204 141.52394,61.667333 136,61.667333 C130.47606,61.667333 126,57.0417204 126,51.334666 C126,45.6276116 130.47606,41 136,41 Z"></path>
            </g>
        </g>
    </svg>
);

function GenderDifferenceCell(props) {
    const { schoolData, scorePrefix, localiser, sigSuffix } = props;

    const scoreM = schoolData[scorePrefix + "_M"];
    const scoreF = schoolData[scorePrefix + "_F"];
    const singleGenderSchool = scoreM === undefined || scoreF === undefined;
    const genderSignificant = significanceColumn(schoolData, `sig_gender_${sigSuffix}`);

    const bestGender = genderSignificant ? (scoreM > scoreF ? "Boys" : "Girls") : "similar";
    const scoreDiff = Math.round(Math.abs(scoreM - scoreF));

    ["Boys", "Girls", "Girls and boys|perform similarly", "outperform", "by {Amount}", "Not applicable"].forEach(
        localiser
    );

    if (singleGenderSchool) {
        return (
            <td>
                <div className={styles.cellCenter}>
                    <span>
                        <span className={styles.rowComparison}>{localiser("Not applicable")}</span>
                    </span>
                </div>
            </td>
        );
    }

    if (bestGender === "similar") {
        return (
            <td>
                <div className={styles.cellCenter}>
                    <span>
                        {localiser("Girls and boys|perform similarly").split("|")[0]}
                        <br />
                        <span className={styles.rowComparison}>
                            {localiser("Girls and boys|perform similarly").split("|")[1]}
                        </span>
                    </span>
                </div>
            </td>
        );
    }

    return (
        <td>
            <div className={styles.cellCenter}>
                <div className={styles.genderIcon}>{bestGender === "Girls" ? <FemaleIcon /> : <MaleIcon />}</div>
                <span>
                    <div className={styles.rowComparison}>{localiser(bestGender)}</div> {localiser("outperform")}{" "}
                    {localiser(bestGender === "Girls" ? "Boys" : "Girls").toLowerCase()}{" "}
                    {localiser("by {Amount}").replace(" {Amount}", "")}
                    <div className={styles.rowScore}>
                        {scoreDiff} {localiser("points")}
                    </div>
                </span>
            </div>
        </td>
    );
}

function SocioeconomicDifferenceRow(props) {
    const { localiser } = props;

    return (
        <>
            <tr>
                <td className={styles.rowHeadingCell}>
                    <div className={styles.rowHeading}>
                        <span className={styles.highlight}>
                            {localiser("Socio-economic|differences in performance").split("|")[0]}
                        </span>{" "}
                        {localiser("Socio-economic|differences in performance").split("|")[1]}
                    </div>
                </td>
                <SocioeconomicDifferenceCell {...props} scorePrefix="read" sigSuffix="read" />
                <SocioeconomicDifferenceCell {...props} scorePrefix="math" sigSuffix="math" />
                <SocioeconomicDifferenceCell {...props} scorePrefix="sci" sigSuffix="scie" />
            </tr>
        </>
    );
}

const LessEquitableIcon = props => (
    <svg height="24px" version="1.1" viewBox="0 0 12.9 18.63" className={styles.socioeconomicDifferenceIcon}>
        <path
            fill="black"
            d="M12.9,3.59c0-1.05-0.74-2-2.07-2.67c-2.45-1.23-6.29-1.23-8.75,0C0.74,1.59,0,2.54,0,3.59c0,0,0,0,0,0.01v2.85
    c0,0,0,0,0,0c0,0,0,0.01,0,0.01v2.85c0,0,0,0,0,0c0,0,0,0.01,0,0.01v2.85c0,0,0,0,0,0c0,0,0,0.01,0,0.01v2.85c0,0,0,0,0,0
    c0,2.04,2.77,3.58,6.45,3.58c3.67,0,6.45-1.54,6.45-3.58c0,0,0,0,0,0L12.9,3.59C12.9,3.59,12.9,3.59,12.9,3.59z M11.46,12.19
    c0,1.02-2.15,2.15-5.02,2.15c-2.87,0-5.01-1.13-5.02-2.15v-0.57c1.16,0.79,2.95,1.28,5.02,1.28c2.07,0,3.85-0.49,5.02-1.28V12.19z
     M11.46,9.32c0,1.02-2.15,2.15-5.02,2.15c-2.87,0-5.01-1.13-5.02-2.15V8.76c1.16,0.79,2.95,1.28,5.02,1.28
    c2.07,0,3.85-0.49,5.02-1.28V9.32z M11.46,6.45c0,1.02-2.15,2.15-5.02,2.15c-2.87,0-5.01-1.13-5.02-2.15V5.87
    c0.2,0.13,0.41,0.26,0.64,0.38c1.23,0.61,2.8,0.92,4.37,0.92c1.57,0,3.15-0.31,4.37-0.92c0.24-0.12,0.45-0.25,0.64-0.38V6.45z
     M2.72,2.2C3.73,1.7,5.09,1.44,6.45,1.44c1.36,0,2.72,0.25,3.73,0.76c0.82,0.41,1.28,0.91,1.28,1.39c0,0.47-0.47,0.98-1.28,1.38
    c-2.02,1.01-5.44,1.01-7.47,0C1.9,4.57,1.43,4.06,1.43,3.59C1.43,3.11,1.9,2.61,2.72,2.2z M6.45,17.2c-2.87,0-5.01-1.13-5.02-2.15
    c0,0,0,0,0,0v-0.56c1.16,0.79,2.95,1.28,5.02,1.28c2.07,0,3.85-0.49,5.02-1.28v0.56C11.46,16.07,9.32,17.2,6.45,17.2z"
        />
    </svg>
);

const MoreEquitableIcon = props => (
    <svg width="28px" height="24px" viewBox="0 0 28 24" version="1.1" className={styles.socioeconomicDifferenceIcon}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" stroke="#000000" strokeWidth="1.466">
                <g id="Group-37">
                    <g id="Group-17" transform="translate(0.000000, 0.070000)">
                        <path
                            d="M16.869,1.7656 C14.579,2.9116 14.579,4.7676 16.869,5.9136 C19.159,7.0576 22.873,7.0576 25.163,5.9136 C27.453,4.7676 27.453,2.9116 25.163,1.7656 C22.873,0.6196 19.159,0.6196 16.869,1.7656"
                            id="Stroke-1"
                        ></path>
                        <path
                            d="M15.1512,6.7714 C15.1512,8.3934 17.7772,9.7034 21.0152,9.7034 C24.2552,9.7034 26.8812,8.3934 26.8812,6.7714"
                            id="Stroke-3"
                        ></path>
                        <path
                            d="M26.8808,3.8398 L26.8808,18.5018 C26.8808,19.3658 26.1328,20.1418 24.9428,20.6798"
                            id="Stroke-5"
                        ></path>
                        <path
                            d="M26.8808,15.5684 C26.8808,16.4384 26.1288,17.2144 24.9308,17.7524"
                            id="Stroke-7"
                        ></path>
                        <path
                            d="M26.8808,12.6368 C26.8808,13.4808 26.1668,14.2408 25.0268,14.7768"
                            id="Stroke-9"
                        ></path>
                        <path
                            d="M26.8808,9.7032 C26.8808,10.5752 26.1248,11.3532 24.9208,11.8932"
                            id="Stroke-11"
                        ></path>
                        <path
                            d="M10.9902,1.7656 C13.2802,2.9116 13.2802,4.7676 10.9902,5.9136 C8.7002,7.0576 4.9862,7.0576 2.6962,5.9136 C0.4062,4.7676 0.4062,2.9116 2.6962,1.7656 C4.9862,0.6196 8.7002,0.6196 10.9902,1.7656"
                            id="Stroke-13"
                        ></path>
                        <path
                            d="M12.7078,6.7714 C12.7078,8.3934 10.0818,9.7034 6.8438,9.7034 C3.6038,9.7034 0.9778,8.3934 0.9778,6.7714"
                            id="Stroke-15"
                        ></path>
                    </g>
                    <line x1="12.7078" y1="9.7732" x2="12.7078" y2="3.9092" id="Stroke-18"></line>
                    <g id="Group-33" transform="translate(0.000000, 3.070000)">
                        <path
                            d="M0.9784,0.8398 L0.9784,15.5018 C0.9784,17.1218 3.6044,18.4338395 6.8424,18.4338395 C7.5824,18.4358 8.3184,18.3618 9.0424,18.2158"
                            id="Stroke-19"
                        ></path>
                        <path
                            d="M0.9784,12.5684 C0.9784,14.1904 3.6044,15.5004 6.8424,15.5004 C7.5824,15.5024 8.3184,15.4304 9.0424,15.2824"
                            id="Stroke-21"
                        ></path>
                        <path
                            d="M0.9784,9.6368 C0.9784,11.2568 3.6044,12.5688 6.8424,12.5688 C7.5824,12.5708 8.3184,12.4968 9.0424,12.3488"
                            id="Stroke-23"
                        ></path>
                        <path
                            d="M0.9784,6.7032 C0.9784,8.3252 3.6044,9.6372 6.8424,9.6372 C7.5824,9.6392 8.3184,9.5652 9.0424,9.4172"
                            id="Stroke-25"
                        ></path>
                        <path
                            d="M11.242,11.1036 L11.242,16.9676 C11.242,18.5856 13.868,19.8996 17.106,19.8996 C20.346,19.8996 22.972,18.5856 22.972,16.9676 L22.972,11.1036"
                            id="Stroke-27"
                        ></path>
                        <path
                            d="M22.9716,14.0352 C22.9716,15.6552 20.3456,16.9672 17.1076,16.9672 C13.8676,16.9672 11.2416,15.6552 11.2416,14.0352"
                            id="Stroke-29"
                        ></path>
                        <path
                            d="M21.2538,9.0292 C23.5438,10.1752 23.5438,12.0312 21.2538,13.1772 C18.9638,14.3212 15.2498,14.3212 12.9598,13.1772 C10.6698,12.0312 10.6698,10.1752 12.9598,9.0292 C15.2498,7.8832 18.9638,7.8832 21.2538,9.0292"
                            id="Stroke-31"
                        ></path>
                    </g>
                    <line x1="15.1512" y1="9.7732" x2="15.1512" y2="3.9092" id="Stroke-34"></line>
                </g>
            </g>
        </g>
    </svg>
);

const equityLineScale = scaleLinear().range([10, 90]).domain([0, 100]).clamp(true);

function SocioeconomicDifferenceCell(props) {
    const { schoolData, scorePrefix, localiser, sigSuffix } = props;

    const topScore = schoolData["top_escs_" + scorePrefix];
    const lowScore = schoolData["btn_escs_" + scorePrefix];
    const difference = topScore - lowScore;

    const diffSignificant = significanceColumn(schoolData, `sig_escs_${sigSuffix}`);

    [
        "to your country",
        "than your country",
        "Most and least advantaged students|perform similarly",
        "Most advantaged students outperform least advantaged students by",
        "Most advantaged students underperform least advantaged students by",
    ].forEach(localiser);

    const copyEl = diffSignificant ? (
        <div>
            {localiser(
                difference > 0
                    ? "Most advantaged students outperform least advantaged students by"
                    : "Most advantaged students underperform least advantaged students by"
            )}
        </div>
    ) : (
        <>
            <div>{localiser("Most and least advantaged students|perform similarly").split("|")[0]}</div>
            <div className={styles.rowComparison}>
                {localiser("Most and least advantaged students|perform similarly").split("|")[1]}
            </div>
        </>
    );

    return (
        <td>
            <div className={styles.cellCenter}>
                {diffSignificant && (
                    <div className={styles.socioeconomicDifferenceVis}>
                        {difference > 0 ? <LessEquitableIcon /> : <MoreEquitableIcon />}
                        <div
                            className={styles.socioeconomicDifferenceLine}
                            style={{ width: equityLineScale(Math.abs(difference)) }}
                        />
                        {difference > 0 ? <MoreEquitableIcon /> : <LessEquitableIcon />}
                    </div>
                )}
                {copyEl}
                {diffSignificant && (
                    <div className={styles.rowScore}>
                        {Math.abs(Math.round(difference))} {localiser("points")}
                    </div>
                )}
            </div>
        </td>
    );
}

const DoubleArrow = () => (
    <svg width="27px" height="15px" viewBox="0 0 27 15" className={styles.socialArrow}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-132.000000, -49.000000)" stroke="#231F20" strokeWidth="1.418">
                <g id="Group-7" transform="translate(133.000000, 49.000000)">
                    <line x1="0.0408" y1="7.38376" x2="24.4288" y2="7.38376" id="Stroke-1"></line>
                    <polyline id="Stroke-3" points="7.4242 14.24856 0.0402 7.38456 7.4242 0.51856"></polyline>
                    <polyline id="Stroke-5" points="17.0458 0.51916 24.4298 7.38316 17.0458 14.24916"></polyline>
                </g>
            </g>
        </g>
    </svg>
);

const IconDiscipline = props => (
    <svg width="23px" height="23px" viewBox="0 0 23 23" {...props}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" transform="translate(-134.000000, -44.000000)" stroke="#000000" strokeWidth="1.334">
                <g id="Group-9" transform="translate(135.000000, 45.000000)">
                    <path
                        d="M6.2224,1.1154 C7.2644,2.1574 7.2644,3.8474 6.2224,4.8894 C5.1804,5.9294 3.4904,5.9294 2.4504,4.8894 C1.4084,3.8474 1.4084,2.1574 2.4504,1.1154 C3.4904,0.0734 5.1804,0.0734 6.2224,1.1154"
                        id="Stroke-1"
                    ></path>
                    <line x1="4.3358" y1="7.0038" x2="4.3358" y2="12.3398" id="Stroke-3"></line>
                    <g id="Group-8" transform="translate(0.000000, 0.267600)">
                        <path
                            d="M6.3368,20.0756 L7.0028,13.4056 L8.3368,13.4056 L8.3368,10.7376 C8.3368,8.5276 6.5468,6.7356 4.3368,6.7356 C2.1248,6.7356 0.3348,8.5276 0.3348,10.7376 L0.3348,13.4056 L1.6688,13.4056 L2.3348,20.0756 L6.3368,20.0756 Z"
                            id="Stroke-4"
                        ></path>
                        <path
                            d="M11.0056,14.0728 L19.0096,14.0728 C19.7456,14.0728 20.3436,13.4748 20.3436,12.7388 L20.3436,1.4008 C20.3436,0.6628 19.7456,0.0668 19.0096,0.0668 L9.6716,0.0668"
                            id="Stroke-6"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const IconHealth = props => (
    <svg width="22px" height="20px" viewBox="0 0 22 20" {...props}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" transform="translate(-134.000000, -46.000000)" stroke="#000000" strokeWidth="1.334">
                <g id="Group-7" transform="translate(135.000000, 47.000000)">
                    <path
                        d="M14.8138,13.3664 L10.8198,17.5344 C10.5658,17.7984 10.1438,17.8084 9.8778,17.5544 C9.8698,17.5464 9.8638,17.5404 9.8578,17.5344 L5.8618,13.3664"
                        id="Stroke-1"
                    ></path>
                    <path
                        d="M0.975,8.0344 L0.975,8.0344 C0.163,6.5784 0.121,4.8144 0.867,3.3204 L0.867,3.3204 C2.113,0.8304 5.143,-0.1796 7.635,1.0664 C8.119,1.3084 8.563,1.6264 8.945,2.0104 L10.339,3.3984 L11.731,2.0064 C13.701,0.0364 16.895,0.0364 18.865,2.0064 C19.249,2.3904 19.567,2.8324 19.809,3.3164 C20.555,4.8084 20.515,6.5724 19.703,8.0304"
                        id="Stroke-3"
                    ></path>
                    <path
                        d="M0.3338,10.6986 L6.1298,10.6986 C6.2558,10.6986 6.3718,10.6266 6.4298,10.5146 L7.9618,7.4486 C8.0418,7.2826 8.2418,7.2126 8.4078,7.2946 C8.4938,7.3346 8.5578,7.4106 8.5838,7.5026 L10.0778,12.4826 C10.1358,12.6586 10.3238,12.7546 10.4998,12.6986 C10.5898,12.6686 10.6618,12.6026 10.7018,12.5186 L12.0978,9.2606 C12.1698,9.0906 12.3678,9.0126 12.5358,9.0846 C12.5958,9.1106 12.6458,9.1526 12.6818,9.2046 L13.6738,10.6946 L20.3438,10.6946"
                        id="Stroke-5"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

const IconSatisfaction = props => (
    <svg width="22px" height="22px" viewBox="0 0 22 22" {...props}>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" transform="translate(-134.000000, -45.000000)" stroke="#000000" strokeWidth="1.334">
                <g id="Group-9" transform="translate(135.000000, 46.000000)">
                    <path
                        d="M17.413,3.0594 C21.321,6.9674 21.321,13.3014 17.413,17.2074 C13.505,21.1154 7.171,21.1154 3.265,17.2074 C-0.643,13.3014 -0.643,6.9674 3.265,3.0594 C7.171,-0.8486 13.505,-0.8486 17.413,3.0594"
                        id="Stroke-1"
                    ></path>
                    <path
                        d="M15.717,12.8018 L15.717,12.8018 C14.245,15.7738 10.643,16.9878 7.671,15.5158 C6.493,14.9318 5.539,13.9798 4.957,12.8018"
                        id="Stroke-3"
                    ></path>
                    <path
                        d="M8.3376,5.5396 L8.3376,5.5396 C6.8956,5.2476 5.4356,5.9496 4.7636,7.2596"
                        id="Stroke-5"
                    ></path>
                    <path
                        d="M12.3394,5.5396 L12.3394,5.5396 C13.7814,5.2476 15.2414,5.9496 15.9134,7.2596"
                        id="Stroke-7"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

const disciplineOutcomes = [
    {
        label: "Optimism",
        schoolMean: "pcor_DISCLIM_opt",
    },
    {
        label: "Assertiveness",
        schoolMean: "pcor_DISCLIM_ast",
    },
    {
        label: "Empathy",
        schoolMean: "pcor_DISCLIM_emp",
    },
    {
        label: "Self-control",
        schoolMean: "pcor_DISCLIM_sct",
    },
    {
        label: "Curiosity",
        schoolMean: "pcor_DISCLIM_cst",
    },
];

const healthOutcomes = [
    {
        label: "Optimism",
        schoolMean: "pcor_ST999_opt",
    },
    {
        label: "Assertiveness",
        schoolMean: "pcor_ST999_ast",
    },
    {
        label: "Empathy",
        schoolMean: "pcor_ST999_emp",
    },
    {
        label: "Self-control",
        schoolMean: "pcor_ST999_sct",
    },
    {
        label: "Curiosity",
        schoolMean: "pcor_ST999_cst",
    },
];

const satisfactionOutcomes = [
    {
        label: "Optimism",
        schoolMean: "pcor_ST016_opt",
    },
    {
        label: "Assertiveness",
        schoolMean: "pcor_ST016_ast",
    },
    {
        label: "Empathy",
        schoolMean: "pcor_ST016_emp",
    },
    {
        label: "Self-control",
        schoolMean: "pcor_ST016_sct",
    },
    {
        label: "Curiosity",
        schoolMean: "pcor_ST016_cst",
    },
];

const getStrongestRelationship = (schoolData, outcomes) => {
    let max;
    outcomes.forEach(outcome => {
        const outcomeValue = Math.abs(schoolData[outcome.schoolMean]);
        if (!max || outcomeValue > max.value) {
            max = {
                value: outcomeValue,
                label: outcome.label,
            };
        }
    });

    return max.label;
};

function SocialRow(props, border) {
    const { schoolData, localiser } = props;

    ["Optimism", "Assertiveness", "Empathy", "Self-control", "Curiosity"].forEach(localiser);

    return (
        <tr className={`${styles[props.border]} ${styles.socialRow}`}>
            <td className={styles.rowHeadingCell}>
                <div className={styles.rowHeading}>
                    <span className={styles.highlight}>{localiser("Social and emotional|skills").split("|")[0]}</span>{" "}
                    {localiser("Social and emotional|skills").split("|")[1]}
                </div>
            </td>
            <td style={{ borderRight: "none" }}>
                {localiser(
                    "The strongest relationships between social and emotional skills and life outcomes were observed for:"
                )}
            </td>
            <td colSpan="2" style={{ borderLeft: "none", borderTop: "none" }}>
                <table className={styles.socialRelationships}>
                    <tbody>
                        <tr>
                            <td>
                                <IconDiscipline className={styles.socialIcon} />
                            </td>
                            <td>{localiser("Classroom disciplinary climate")}</td>
                            <td>
                                <DoubleArrow />
                            </td>
                            <td>{localiser(getStrongestRelationship(schoolData, disciplineOutcomes))}</td>
                        </tr>

                        <tr>
                            <td>
                                <IconHealth className={styles.socialIcon} />
                            </td>
                            <td>{localiser("Students’ perceived health")}</td>
                            <td>
                                <DoubleArrow />
                            </td>
                            <td>{localiser(getStrongestRelationship(schoolData, healthOutcomes))}</td>
                        </tr>
                        <tr>
                            <td>
                                <IconSatisfaction className={styles.socialIcon} />
                            </td>
                            <td>{localiser("Students’ overall life satisfaction")}</td>
                            <td>
                                <DoubleArrow />
                            </td>
                            <td>{localiser(getStrongestRelationship(schoolData, satisfactionOutcomes))}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
}

const IconMeteor = () => (
    <svg width="23px" height="26px" viewBox="0 0 23 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" transform="translate(-134.000000, -44.000000)" stroke="#000000" strokeWidth="1.418">
                <path
                    d="M146.297175,56.1257815 L146.297175,56.1257815 C146.143665,57.9366057 144.907612,59.4554931 143.205049,59.9311662 C142.604965,59.4024137 142.507277,58.4857733 142.977775,57.8345299 C141.709824,57.450725 140.342191,57.8610696 139.470973,58.8838688 C137.97176,60.4272544 137.97176,62.9321937 139.470973,64.4755794 C140.982147,65.9903838 143.390457,65.9903838 144.901631,64.4755794 C147.280036,62.4136488 147.868158,58.8879518 146.297175,56.1257815 Z M137.273989,55.4275833 L137.273989,55.4275833 C134.241672,58.5511018 134.241672,63.6140599 137.275982,66.7375784 C137.425505,66.8927335 137.583002,67.0397227 137.744486,67.1805872 C140.976166,69.8610969 145.663199,69.5467035 148.530045,66.4558493 C151.664037,63.2588362 154.180003,59.4759082 155.942375,55.3091754 C156.103859,54.9029138 155.914464,54.4374483 155.517731,54.2700441 C155.344285,54.1965495 155.150902,54.1924665 154.973469,54.2537119 L151.556381,55.4275833 L155.699151,47.749444 C155.906489,47.3635976 155.772916,46.8777169 155.398113,46.6613163 C155.16087,46.5245348 154.8698,46.5265763 154.634551,46.6653993 L149.36139,49.7685027 L150.101028,45.959035 C150.188748,45.5282753 149.917614,45.1056816 149.498951,45.0158549 C149.291613,44.9729831 149.0763,45.0178964 148.902854,45.1403874 C144.732172,48.2006189 140.840599,51.6446553 137.273989,55.4275833 Z"
                    id="Stroke-1"
                ></path>
            </g>
        </g>
    </svg>
);

const IconHourglass = () => (
    <svg width="17px" height="26px" viewBox="0 0 17 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" transform="translate(-136.000000, -44.000000)" stroke="#000000" strokeWidth="1.418">
                <path
                    d="M138.499805,45 L138.499805,45 C137.670914,45 137,45.716083 137,46.599456 L137,49.1251275 C137,53.5419925 140.358471,57.1244475 144.500975,57.1244475 C148.641529,57.1244475 152,53.5419925 152,49.1251275 L152,46.599456 C152,45.716083 151.329086,45 150.500195,45 L138.499805,45 Z M138.499805,69 L138.499805,69 C137.670914,69 137,68.283917 137,67.400544 L137,64.8748725 C137,60.4559674 140.358471,56.8755525 144.500975,56.8755525 C148.641529,56.8755525 152,60.4559674 152,64.8748725 L152,67.400544 C152,68.283917 151.329086,69 150.500195,69 L138.499805,69 Z M137.979066,52.99932 L151.020934,52.99932 L137.979066,52.99932 Z M151.048238,68.8898334 C149.76492,66.0132608 147.313353,63.399864 144.500975,63.399864 C141.686647,63.399864 139.23508,66.0132608 137.951762,68.8898334 L151.048238,68.8898334 Z"
                    id="Stroke-1"
                ></path>
            </g>
        </g>
    </svg>
);

const IconEar = () => (
    <svg width="27px" height="26px" viewBox="0 0 27 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Artboard" transform="translate(-132.000000, -44.000000)" stroke="#000000" strokeWidth="1.418">
                <path
                    d="M136.944542,62.7386382 L136.944542,62.7386382 C132.326092,58.827761 131.668295,51.8138874 135.474405,47.0706483 C136.09852,46.2933691 136.823682,45.6058544 137.626116,45.0285053 M140.560446,59.9498176 L140.560446,59.9498176 C137.443834,57.1467163 137.128805,52.2790309 139.857079,49.0760694 C140.003696,48.9026606 140.16022,48.7353722 140.322688,48.5762442 M157.887065,51.3813856 L157.887065,51.3813856 C157.173791,47.1889743 153.288428,44.3838328 149.208896,45.1162298 C145.129364,45.8486267 142.399109,49.8390679 143.110401,54.0314792 C143.451188,56.0287399 144.542894,57.8077096 146.149742,58.9828088 C147.497038,59.8845343 148.234088,61.4880551 148.055769,63.1303379 C147.667431,65.8640758 149.45656,68.4223647 152.1036,68.9160696 C154.819986,69.4240552 157.423437,67.573682 157.918766,64.7848614 C157.972262,64.4788459 158,64.1707904 158,63.8606948 M154.453442,52.9257435 L154.453442,52.9257435 C154.243423,51.2059368 152.717808,49.9859553 151.043595,50.2001661 C149.367401,50.4143769 148.180592,51.9811759 148.38863,53.7030227"
                    id="Stroke-1"
                ></path>
            </g>
        </g>
    </svg>
);

const IconLightbulb = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 27 27">
        <g id="Page-1" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#000">
            <path
                d="M22.781 26.156a2.17 2.17 0 00-.843-1.77 12.649 12.649 0 00-8.438-2.652c-3.04-.16-6.035.782-8.438 2.653a2.17 2.17 0 00-.843 1.77"
                strokeWidth="1.6875"
            />
            <path
                d="M11.813 25.953l1.687-4.219 1.688 4.22M20.25 7.594A6.752 6.752 0 0013.5.844a6.752 6.752 0 00-6.75 6.75 6.575 6.575 0 003.86 6.18v3.101h5.78v-3.102a6.575 6.575 0 003.86-6.18zm0 0M13.5 16.875v1.688M10.055 13.5h6.89M13.5 13.5v-3.375"
                strokeWidth="1.6875"
            />
            <path
                d="M16.031 7.594L13.5 10.125l-2.531-2.531M24.469 7.594h1.687M24.117 11.46l1.195 1.196M24.117 3.723l1.195-1.192M2.531 7.594H.844M2.883 11.46l-1.196 1.196M2.883 3.723L1.687 2.53"
                strokeWidth="1.6875"
            />
        </g>
    </svg>
);

const IconHouse = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 27 27">
        <g id="Page-1" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#000">
            <path
                d="M2.531 10.574V24.47h5.063v-6.75c0-.934.754-1.688 1.687-1.688h1.688c.933 0 1.687.754 1.687 1.688v6.75H24.47V10.574"
                strokeWidth="1.6875"
            />
            <path
                d="M26.156 11.813l-11.66-8.551a1.688 1.688 0 00-1.992 0l-11.66 8.55M17.719 5.625V2.531h5.062v6.805M16.031 16.031h5.063v5.063H16.03zm0 0M.844 24.469h25.312"
                strokeWidth="1.6875"
            />
        </g>
    </svg>
);

const IconGlasses = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 27 27">
        <g id="Page-1" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#000">
            <path
                d="M4.219 17.719h20.25M4.219 21.094h20.25M3.375 6.75a4.219 4.219 0 108.437 0 4.219 4.219 0 00-8.437 0zm0 0M15.188 6.75a4.219 4.219 0 108.437 0 4.219 4.219 0 00-8.438 0zm0 0"
                strokeWidth="1.6875"
            />
            <path
                d="M11.813 6.832c0-.934.753-1.687 1.687-1.687s1.688.753 1.688 1.687M26.156 6.75h-2.531M3.375 6.75H.844M26.156 24.469H4.22a3.376 3.376 0 01-3.375-3.375v-3.375a3.376 3.376 0 013.375-3.375h21.937"
                strokeWidth="1.6875"
            />
        </g>
    </svg>
);

const IconLightning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 27 27">
        <g id="Page-1" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="#000">
            <path
                d="M26.156 13.5a3.376 3.376 0 00-2.453-3.246 2.534 2.534 0 00-1.558-4.34 2.684 2.684 0 00-.461-.406 3.377 3.377 0 00-6.145-2.79 2.531 2.531 0 00-4.078 0 3.374 3.374 0 00-6.14 2.79 2.57 2.57 0 00-.466.406A2.537 2.537 0 002.68 7.582a2.536 2.536 0 00.62 2.672 3.372 3.372 0 000 6.492 2.528 2.528 0 00-.62 2.672 2.537 2.537 0 002.175 1.668c.137.152.293.289.465.406a3.375 3.375 0 006.14 2.79 2.531 2.531 0 004.08 0 3.375 3.375 0 006.144-2.79c.168-.117.324-.254.46-.406a2.534 2.534 0 001.559-4.34 3.368 3.368 0 002.453-3.246zm0 0"
                strokeWidth="1.6875"
            />
            <path
                d="M15.188 5.906l-6.649 7.742a.42.42 0 00.32.696h2.954v6.75l6.648-7.742a.42.42 0 00-.32-.696h-2.953zm0 0"
                strokeWidth="1.6875"
            />
        </g>
    </svg>
);

function EngagementRow(props, border = null) {
    const { localiser } = props;
    return (
        <tr className={`${styles[props.border]} ${styles.engagementRow}`}>
            <td className={styles.rowHeadingCell}>
                <div className={styles.rowHeading}>
                    {localiser("Student|engagement and feelings").split("|")[0]}{" "}
                    <span className={styles.highlight}>
                        {localiser("Student|engagement and feelings").split("|")[1]}
                    </span>
                </div>
            </td>
            <td colSpan="3" style={{ borderBottom: "none" }}>
                <EngagementItem {...props} scorePrefix="mot_sci_1" icon={<IconMeteor />}>
                    {localiser("Believe what they learn in science is important for their future.")}
                </EngagementItem>
                <EngagementItem {...props} scorePrefix="ada_ins_2" icon={<IconHourglass />}>
                    {localiser("Observe their teachers providing individual help to struggling students.")}
                </EngagementItem>
                <EngagementItem {...props} scorePrefix="dis_eng_2" icon={<IconEar />}>
                    {localiser("Experience noise and disorder.")}
                </EngagementItem>
            </td>
        </tr>
    );
}

function CovidRow(props, border = null) {
    const { localiser } = props;
    return (
        <tr className={`${styles[props.border]} ${styles.covidRow}`}>
            <td className={styles.rowHeadingCell}>
                <div className={styles.rowHeading}>
                    {localiser("Consequences of the|COVID-19 school closures").split("|")[0]}{" "}
                    <span className={styles.highlight}>
                        {localiser("Consequences of the|COVID-19 school closures").split("|")[1]}
                    </span>
                </div>
            </td>
            <td colSpan="3" style={{ borderBottom: "none" }}>
                <EngagementItem {...props} scorePrefix="sch_act_7" icon={<IconLightbulb />}>
                    {localiser("Received tips on how to study more effectively on their own.")}
                </EngagementItem>
                <EngagementItem {...props} scorePrefix="stu_pro_5" icon={<IconHourglass />}>
                    {localiser("Had problems finding time to study due to household responsibilities.")}
                </EngagementItem>
                <EngagementItem {...props} scorePrefix="stu_sup_1" icon={<IconHouse />}>
                    {localiser("Reported support at home on completing schoolwork.")}
                </EngagementItem>
            </td>
        </tr>
    );
}

function ResilienceRow(props, border = null) {
    const { localiser } = props;
    return (
        <tr className={`${styles[props.border]} ${styles.resilienceRow}`}>
            <td className={styles.rowHeadingCell}>
                <div className={styles.rowHeading}>
                    {localiser("Resilience|among disadvantaged students").split("|")[0]}{" "}
                    <span className={styles.highlight}>
                        {localiser("Resilience|among disadvantaged students").split("|")[1]}
                    </span>
                </div>
            </td>
            <td colSpan="3" style={{ borderBottom: "none" }}>
                <div className={styles.resilienceDescription}></div>
                <EngagementItem {...props} scorePrefix="resread_dis_40" icon={<IconGlasses />}>
                    {localiser("That reported being able to work well are resilient in Reading.")}
                </EngagementItem>
                <EngagementItem {...props} scorePrefix="resmath_gm_1" icon={<IconLightning />}>
                    {localiser("That possess a growth mindset are resilient in Mathematics.")}
                </EngagementItem>
            </td>
        </tr>
    );
}

function EngagementItem(props) {
    const { scorePrefix, icon, children, schoolData } = props;

    const score = Math.round(schoolData[scorePrefix]);

    return (
        <div className={styles.engagementItem}>
            <div className={styles.engagementIcon}>{icon}</div>
            <div className={styles.rowScore}>{score}%</div>
            <div>{children}</div>
        </div>
    );
}

function Flags(props) {
    const { countryScore, oecdScore, params } = props;
    return (
        <div className={styles.flagsContainer}>
            <div className={styles.flags}>
                <div className={styles.flagContainer}>
                    <Flag className={styles.flagImg} {...params} />
                </div>
                <div className={styles.flagContainer}>
                    <span className={styles.flagScore}>{Math.round(countryScore)}</span>
                </div>
            </div>
            <div className={styles.flags}>
                <div className={styles.flagContainer}>
                    <OECDLogo className={styles.oecdLogo} />
                </div>
                <div className={styles.flagContainer}>
                    <span className={styles.flagScore}>{Math.round(oecdScore)}</span>
                </div>
            </div>
        </div>
    );
}

export { ExecutiveSummary, ExecutiveSummaryCovid };
