import React from "react";
import Flag from "../../components/flag";
import { HexagonSvg, OecdSvg } from "../../components/shapes";

import styles from "./table.module.scss";
import { formatBreaks } from "../../modules/format";
import { getFormat } from "../../modules/format";

const Table = props => {
    const { batchConfig, params, visOptions, localiser } = props;

    const domainRows = visOptions.grouped ? (
        <GroupDomainRows {...props} />
    ) : (
        visOptions.domains.map((domain, i) => {
            return <DomainRow key={domain.label + i} {...props} domain={domain} />;
        })
    );
    const tableClassNames = visOptions.grouped ? [styles.table, styles.grouped] : [styles.table];

    ["table-header-group", "table-header-national", "table-header-your-school"].forEach(localiser);

    const firstColumnName = batchConfig.groupReport
        ? "table-header-group"
        : batchConfig.nationalReport
        ? "table-header-national"
        : "table-header-your-school";

    return (
        <table className={tableClassNames.join(" ")}>
            <thead className={styles.headerRow}>
                <tr>
                    <td />
                    {visOptions.grouped && <td />}
                    <td className={styles.headerCell}>
                        <div>
                            <HexagonSvg />
                            {formatBreaks(localiser(firstColumnName))}
                        </div>
                    </td>
                    <td className={styles.headerCell}>
                        <div>
                            <Flag {...params} /> {formatBreaks(localiser("table-header-country"))}
                        </div>
                    </td>
                    <td className={styles.headerCell}>
                        <div>
                            <OecdSvg /> {formatBreaks(localiser("OECD"))}
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>{domainRows}</tbody>
        </table>
    );
};

const GroupDomainRows = props => {
    const { localiser, visOptions, schoolData } = props;

    return visOptions.domains.map(domain => {
        return domain.groupDomains.map((groupDomain, i) => {
            const formatValue = key => {
                if (!key) return null;
                const valRaw = schoolData[key];
                if (valRaw === undefined) return null;

                if (groupDomain.format) {
                    return getFormat(groupDomain.format)(valRaw, schoolData, localiser);
                }
                // Default to no format.
                return valRaw;
            };
            return (
                <tr key={domain.groupLabel + groupDomain.label} className={styles.bodyRow}>
                    {i === 0 && (
                        <td
                            rowSpan="3"
                            title={domain.groupLabel}
                            className={styles.domainLabelCell}
                            data-bottomstroke={domain === visOptions.domains[visOptions.domains.length - 1]}
                        >
                            {localiser(domain.groupLabel)}
                        </td>
                    )}
                    <td title={groupDomain.label} className={styles.domainLabelCell}>
                        {localiser(groupDomain.label)}
                    </td>
                    <td
                        data-missing={formatValue(groupDomain.columns.school) === null}
                        title={groupDomain.columns.school}
                        className={styles.scoreCell}
                    >
                        {formatValue(groupDomain.columns.school)}
                    </td>
                    <td
                        data-missing={formatValue(groupDomain.columns.country) === null}
                        title={groupDomain.columns.country}
                        className={styles.scoreCell}
                    >
                        {formatValue(groupDomain.columns.country)}
                    </td>
                    <td
                        data-missing={formatValue(groupDomain.columns.oecd) === null}
                        title={groupDomain.columns.oecd}
                        className={styles.scoreCell}
                    >
                        {formatValue(groupDomain.columns.oecd)}
                    </td>
                </tr>
            );
        });
    });
};

const DomainRow = props => {
    const { domain, schoolData, localiser } = props;

    const formatValue = key => {
        if (!key) return null;
        const valRaw = schoolData[key];
        if (valRaw === undefined) return null;

        if (domain.format) {
            return getFormat(domain.format)(valRaw, schoolData, localiser);
        }
        // Default to no format.
        return valRaw;
    };
    return (
        <tr className={styles.bodyRow}>
            <td title={domain.label} className={styles.domainLabelCell}>
                {localiser(domain.label)}
            </td>
            <td
                data-missing={formatValue(domain.columns.school) === null}
                title={domain.columns.school}
                className={styles.scoreCell}
            >
                {formatValue(domain.columns.school)}
            </td>
            <td
                data-missing={formatValue(domain.columns.country) === null}
                title={domain.columns.country}
                className={styles.scoreCell}
            >
                {formatValue(domain.columns.country)}
            </td>
            <td
                data-missing={formatValue(domain.columns.oecd) === null}
                title={domain.columns.oecd}
                className={styles.scoreCell}
            >
                {formatValue(domain.columns.oecd)}
            </td>
        </tr>
    );
};

export default Table;
