import isDev from "./is-dev";

const devApiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";
const prodApiUrl = "";

export const API_URL = isDev ? devApiUrl : prodApiUrl;

const makeFetcher = urlGetter => async props => {
    const url = urlGetter(props);
    const auth = props.auth || "unset";
    const res = await fetch(url, {
        headers: new Headers({
            Authentication: auth,
        }),
    });

    if (!res.ok) {
        if (res.status === 401) {
            throw new Error(
                "Unauthorised. Make sure you have provided the auth param in the url, eg: auth=AUTH-STRING."
            );
        }
        throw new Error(
            `${res.statusText} : ${res.text} -  Trying to get data from: ${url}. Please check the url parameters are correct, eg: that the year, countryCode, batchNumber, and schoolId are correct and you have a corresponding folder.`
        );
    }
    const body = await res.json();
    return body;
};

export const fetchSchoolData = makeFetcher(
    ({ year, countryCode, batchNumber, schoolId }) =>
        `${API_URL}/data/${year}/${countryCode}/${batchNumber}/${schoolId}`
);

export const fetchBatchConfig = makeFetcher(
    ({ year, countryCode, batchNumber }) => `${API_URL}/config/${year}/${countryCode}/${batchNumber}`
);

export const fetchBatchLocalisation = makeFetcher(
    ({ year, countryCode, batchNumber }) => `${API_URL}/localisation/${year}/${countryCode}/${batchNumber}`
);

export const fetchReportData = makeFetcher(({ year, countryCode, batchNumber, schoolId, localisationLanguage }) => {
    return localisationLanguage
        ? `${API_URL}/report-data/${year}/${countryCode}/${batchNumber}/${schoolId}?localisationLanguage=${localisationLanguage}`
        : `${API_URL}/report-data/${year}/${countryCode}/${batchNumber}/${schoolId}`;
});
