import React from "react";
import { API_URL } from "../modules/fetch-data";

const GREEN = "#00ae88";
const GREEN_DARK = "#005441";

export default function GenerateReportPage(props) {
    // WH
    const {
        year,
        countryCode,
        batchNumber,
        schoolId,
        auth,
        openReport,
        reuseReport,
        reportFileOutput,
        localisationLanguage,
    } = props;
    const [progress, setProgress] = React.useState(0);
    const [done, setDone] = React.useState(false);
    const [started, setStarted] = React.useState(false);
    const [outputMap, setOutputMap] = React.useState(null);

    const generateReports = React.useCallback(
        (open = false) => {
            setStarted(true);
            (async () => {
                const schoolsParam = schoolId ? "schools=" + schoolId : null;
                const reuseParam = reuseReport ? "reuseReport=true" : null;
                // WH
                const reportFileOutputParam = reportFileOutput ? "reportFileOutput=true" : null;
                // WH
                const localisationLanguageParam = localisationLanguage
                    ? `localisationLanguage=${localisationLanguage}`
                    : null;
                const queryParams = [
                    schoolsParam,
                    reuseParam,
                    reportFileOutputParam,
                    localisationLanguageParam,
                ].filter(d => Boolean(d));
                const queryParamString = queryParams.length ? "?" + queryParams.join("&") : "";

                const url = `${API_URL}/run-report/${year}/${countryCode}/${batchNumber}${queryParamString}`;

                const response = await fetch(url, {
                    headers: new Headers({
                        Authentication: auth || "unset",
                    }),
                });

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                const awaitProgressUpdate = async () => {
                    const result = await reader.read();

                    if (result.done) {
                        setProgress(100);
                        setDone(true);
                        return;
                    }

                    const progressString = decoder.decode(result.value || new Uint8Array(), {
                        stream: !result.done,
                    });

                    if (isNaN(progressString)) {
                        // Is a json of output file map.
                        const outputMap = JSON.parse(progressString);
                        setOutputMap(outputMap);
                        if (open) {
                            // Navigate to the first result.
                            if (outputMap.length === 1) {
                                console.log("navigating to " + outputMap[0].url);
                                window.location = outputMap[0].url;
                            }
                        }
                    } else {
                        const progress = parseFloat(progressString);
                        setProgress(progress);
                        awaitProgressUpdate();
                    }
                };
                awaitProgressUpdate();
            })();
        },
        [auth, batchNumber, countryCode, schoolId, year, reuseReport, localisationLanguage, reportFileOutput]
    );

    React.useLayoutEffect(() => {
        if (openReport) {
            generateReports(true);
        }
    }, [openReport, generateReports]);

    return (
        <div>
            <h2>Generating reports...</h2>
            <ul>
                <li>Year: {year}</li>
                <li>Country: {countryCode}</li>
                <li>Batch: {batchNumber}</li>
                <li>School IDs: {schoolId || "All schools"}</li>
            </ul>
            {started && (
                <>
                    <p>Progress: {progress === 0 ? "initializing..." : done ? "done!" : progress + "%"}</p>
                    <div
                        style={{
                            width: 400,
                            height: 16,
                            border: `1px solid ${GREEN_DARK}`,
                            marginTop: 16,
                        }}
                    >
                        <div
                            style={{
                                width: done ? "100%" : progress + "%",
                                height: "100%",
                                background: GREEN,
                            }}
                        />
                    </div>
                </>
            )}
            {!started && <button onClick={() => generateReports(false)}>Start generating...</button>}
            <ul>
                {outputMap &&
                    outputMap.map(entry => (
                        <li key={entry.id}>
                            {entry.url && <a href={entry.url}>{entry.name}</a>}
                            {!entry.url && <span>{entry.name}</span>}
                        </li>
                    ))}
            </ul>
        </div>
    );
}
