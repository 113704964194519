/**
 * This function calculates whether a value is significant using its *absolute* standard error.
 * @param {number} value
 * @param {number} standardError
 * @param {number} errorThreshold
 */

export function significance(value, standardError, errorThreshold = 0.05) {
	return Math.abs(standardError / value) <= errorThreshold;
}

window.missingSig = [];

export function significanceColumn(schoolData, sigColumn) {
	if (schoolData[sigColumn] === undefined || schoolData[sigColumn] === null) {
		console.log(" ----- ----- Missing significance column: " + sigColumn);
		if (!window.missingSig.includes(sigColumn)) {
			window.missingSig.push(sigColumn);
		}
	}
	return schoolData[sigColumn] !== 0;
}
