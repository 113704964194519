import React from "react";
import useComponentSize from "../../modules/hooks/use-component-size";
import { scaleLinear, scaleBand } from "d3-scale";
import { HexagonPolygonSmall, HexagonSvg } from "../../components/shapes";

import styles from "./big-five.module.scss";
import { significanceColumn } from "../../modules/significance";
import { formatDecimal } from "../../modules/format";

const padding = {
    top: 68,
    bottom: 0,
    left: 128,
    right: 34,
};

const SCHOOL_RADIUS = 8;
const CIRCLE_RADIUS = 7;
const SQUARE_RADIUS = 5;

const BAND_HEIGHT = 20;
const BAND_PADDING = 13;

const useDomains = (schoolData, visOptions) => {
    const bands = visOptions.domains.map(d => d.label);
    // TODO: data based domain?
    let x = visOptions.bipolar ? [-1, 1] : visOptions.xDomain.map(d => schoolData[d]);

    if (x.length > 0 && (x[0] === undefined || x[1] === undefined)) {
        console.log("----- ----- Couldn't find " + visOptions.xDomain + " in schoolData");
        console.log("----- ----- Making range [-2, 2].");
        x = [-2, 2];
    }

    return {
        bands,
        x,
    };
};

const useScales = (dimensions, domains, visOptions) => {
    if (!dimensions) return undefined;

    const innerHeight = domains.bands.length * (BAND_HEIGHT + BAND_PADDING * 2);
    const totalHeight = padding.top + innerHeight + padding.bottom;

    const frame = {
        top: padding.top,
        right: dimensions.width - padding.right,
        bottom: padding.top + innerHeight,
        left: padding.left,
    };

    const bands = scaleBand()
        .domain(domains.bands)
        .rangeRound([frame.top, frame.bottom])
        .paddingOuter(0)
        .paddingInner(BAND_PADDING / innerHeight);

    const bandwidth = bands.bandwidth();

    const x = scaleLinear().domain(domains.x).rangeRound([frame.left, frame.right]);

    return {
        frame,
        bands,
        x,
        bandwidth,
        height: totalHeight,
    };
};

const BigFive = props => {
    const { visOptions, schoolData, localiser } = props;
    const ref = React.useRef();
    const dimensions = useComponentSize(ref);
    const domains = useDomains(schoolData, visOptions);
    const scales = useScales(dimensions, domains, visOptions);

    const chartProps = {
        ...props,
        dimensions,
        domains,
        scales,
    };

    let chartContent;
    let labelContent;
    if (scales) {
        chartContent = (
            <>
                <AxisGrid {...chartProps} />
                <Data {...chartProps} />
            </>
        );
        labelContent = <DomainLabels {...chartProps} />;
    }

    return (
        <div className={styles.container}>
            <svg
                className={styles.svg}
                ref={ref}
                height={scales && scales.height}
                style={{ height: scales && scales.height }}
            >
                {chartContent}
            </svg>
            {labelContent}
            {scales && <Legend {...chartProps} />}
            <p className={styles.notes}>
                <span className={styles.notesHighlight}>{localiser("Note: ")}</span>
                {localiser("statistically significant differences are shown by filled shapes.")}
            </p>
            {visOptions.relationshipParagraph && <RelationshipParagraph {...chartProps} />}
        </div>
    );
};

const RelationshipParagraph = props => {
    const { schoolData, localiser } = props;
    const { domains, relationshipParagraph } = props.visOptions;

    [
        "significant",
        "not significant",
        "positive",
        "negative",
        "increasing",
        "decreasing",
        "increase",
        "decrease",
        "fostering",
        "discouraging",
    ].forEach(localiser);

    const localised = localiser(relationshipParagraph);
    const split = localised.split("[");

    const firstPara = split[0];
    const optionalPara = split[1].replace("]", "");

    let maxDomain;
    domains.forEach(domain => {
        const schoolVal = schoolData[domain.schoolMean];
        if (!maxDomain || Math.abs(schoolVal) > Math.abs(maxDomain.value)) {
            maxDomain = {
                label: domain.label,
                value: schoolVal,
                positive: schoolVal > 0,
                significant: domain.schoolSignificance
                    ? significanceColumn(schoolData, domain.schoolSignificance)
                    : false,
            };
        }
    });

    let valueFormat;
    try {
        valueFormat = formatDecimal(maxDomain.value, localiser("language-tag", 1));
    } catch (e) {
        console.log("Error in big-five.jsx");
        console.log("Usually this happens when a column value does not match the config.");
        console.log("maxDomain", maxDomain);
        console.log("domains", domains);
        console.log("schoolData", schoolData);
        console.log(e);
    }

    const replacedFirstParagraph = firstPara
        .replace(/{skill with strongest relationship}/g, localiser(maxDomain.label))
        .replace(/{value of strongest relationship}/g, maxDomain.positive ? "+" + valueFormat : valueFormat)
        .replace(
            /{significant\/not significant}/g,
            localiser(maxDomain.significant ? "significant" : "not significant")
        )
        .replace(
            /{significant-f\/not significant-f}/g,
            localiser(maxDomain.significant ? "significant-f" : "not significant-f")
        )
        .replace(/{positive\/negative}/g, localiser(maxDomain.positive ? "positive" : "negative"))
        .replace(/{positive-f\/negative-f}/g, localiser(maxDomain.positive ? "positive-f" : "negative-f"));

    const replacedOptionalPara = maxDomain.significant
        ? optionalPara
              .replace(/{increasing\/decreasing}/g, localiser(maxDomain.positive ? "increasing" : "decreasing"))
              .replace(/{fostering\/discouraging}/g, localiser(maxDomain.positive ? "fostering" : "discouraging"))
              .replace(/{increase\/decrease}/g, localiser(maxDomain.positive ? "increase" : "increase"))
              .replace(/{skill with strongest relationship}/g, localiser(maxDomain.label))
        : null;

    return (
        <p className={styles.relationshipParagraph}>
            {replacedFirstParagraph}
            {replacedOptionalPara}
        </p>
    );
};

const AxisGrid = props => {
    const { visOptions, localiser, scales, dimensions } = props;

    const xTicks = scales.x.ticks(10);

    // Lines protrude past the axis a bit.
    const linesStartY = scales.frame.top - 8;

    return (
        <g>
            <g>
                {xTicks.map((yVal, i) => (
                    <line
                        key={yVal}
                        className={"gridLine"}
                        y1={linesStartY}
                        y2={scales.frame.right}
                        x1={scales.x(yVal)}
                        x2={scales.x(yVal)}
                        data-bottom={visOptions.bipolar ? yVal === 0 : i === 0}
                    />
                ))}
            </g>
            <g>
                {xTicks.map(yVal => {
                    let align = "start";
                    if (yVal === 0) {
                        align = "middle";
                    }
                    if (yVal < 0) {
                        align = "end";
                    }
                    const label = visOptions.bipolar
                        ? yVal === 0
                            ? formatDecimal(0, localiser("language-tag"), 2, 2)
                            : formatDecimal(yVal, localiser("language-tag", 1))
                        : yVal;
                    return (
                        <text
                            key={yVal}
                            className={"gridLabel"}
                            data-align={align}
                            x={scales.x(yVal)}
                            y={linesStartY - 8}
                        >
                            {label}
                        </text>
                    );
                })}
            </g>
            <g>
                <line
                    className={"axisLine"}
                    x1={visOptions.bipolar ? scales.frame.left - 24 : scales.frame.left}
                    x2={scales.frame.right + 36}
                    y1={scales.frame.top}
                    y2={scales.frame.top}
                />
                <text className={"axisText"} x={dimensions.width} y={16} data-align="end">
                    {localiser(visOptions.xAxisLabel) || "-"}
                </text>
            </g>
        </g>
    );
};

const DomainLabels = props => {
    const { visOptions, localiser, scales } = props;

    const labels = visOptions.domains.map(domain => {
        const yStart = scales.bands(domain.label);
        const yMid = yStart + scales.bandwidth / 2;
        return (
            <div
                key={domain.label}
                className={styles.domainLabel}
                style={{
                    top: yMid,
                    maxWidth: padding.left - 16,
                }}
            >
                {localiser(domain.label)}
            </div>
        );
    });

    return <div className={styles.labelContainer}>{labels}</div>;
};

const Data = props => {
    return (
        <g>
            {props.visOptions.domains.map(d =>
                props.visOptions.comparison ? (
                    <DomainDataComparison key={d.label} {...props} domain={d} />
                ) : (
                    <DomainDataRange key={d.label} {...props} domain={d} />
                )
            )}
        </g>
    );
};

const DomainDataComparison = props => {
    const { domain, batchConfig, schoolData, visOptions, scales } = props;
    const yStart = scales.bands(domain.label);
    const yMiddle = yStart + scales.bandwidth / 2;

    const schoolX = scales.x(schoolData[domain.schoolMean]);

    const schoolSignificant = visOptions.bipolar ? significanceColumn(schoolData, domain.schoolSignificance) : true;

    const schoolShape = (
        <HexagonPolygonSmall
            className={styles.chartDatum}
            data-datum="school"
            transform={`translate(${schoolX - SCHOOL_RADIUS} ${yMiddle - SCHOOL_RADIUS - 1})`}
            data-significant={schoolSignificant}
        />
    );

    let country = null;

    if (schoolData[domain.comparison] !== undefined && !batchConfig.nationalReport) {
        const countryX = scales.x(schoolData[domain.comparison]);
        const countrySignificant = significanceColumn(schoolData, domain.comparisonSignificance);

        country = (
            <rect
                className={styles.chartDatum}
                data-significant={countrySignificant}
                data-datum={"country"}
                x={countryX - SQUARE_RADIUS}
                y={yMiddle - SQUARE_RADIUS}
                width={2 * SQUARE_RADIUS}
                height={2 * SQUARE_RADIUS}
                style={{
                    transformOrigin: `${countryX}px ${yMiddle}px`,
                    transform: "rotate(45deg)",
                }}
            />
        );
    }

    return (
        <g>
            {schoolShape}
            {country}
        </g>
    );
};

const DomainDataRange = props => {
    const { domain, schoolData, visOptions, scales } = props;
    const yStart = scales.bands(domain.label);
    const yMiddle = yStart + scales.bandwidth / 2;

    const schoolX = scales.x(schoolData[domain.schoolMean]);
    const schoolSignificant = visOptions.bipolar
        ? domain.schoolSignificance
            ? significanceColumn(schoolData, domain.schoolSignificance)
            : false
        : true;
    const schoolShape = (
        <HexagonPolygonSmall
            className={styles.chartDatum}
            data-datum="school"
            transform={`translate(${schoolX - SCHOOL_RADIUS} ${yMiddle - SCHOOL_RADIUS - 1})`}
            data-significant={schoolSignificant}
        />
    );

    const lowCx = scales.x(schoolData[domain.range[0]]);
    const lowSignificant = significanceColumn(schoolData, domain.rangeSignificance[0]);
    const low = (
        <rect
            className={styles.chartDatum}
            data-datum={"quartile"}
            data-significant={lowSignificant}
            x={lowCx - SQUARE_RADIUS}
            y={yMiddle - SQUARE_RADIUS}
            width={SQUARE_RADIUS * 2}
            height={SQUARE_RADIUS * 2}
            style={{
                transformOrigin: `${lowCx}px ${yMiddle}px`,
                transform: "rotate(45deg)",
            }}
        />
    );

    const medianCx = scales.x(schoolData[domain.range[1]]);
    const medianSignificant = significanceColumn(schoolData, domain.rangeSignificance[1]);
    const median = (
        <circle
            className={styles.chartDatum}
            data-datum={"quartile"}
            data-significant={medianSignificant}
            cx={medianCx}
            cy={yMiddle}
            r={CIRCLE_RADIUS}
            style={{
                transformOrigin: `${medianCx}px ${yMiddle}px`,
            }}
        />
    );

    const highCx = scales.x(schoolData[domain.range[2]]);
    const highSignificant = significanceColumn(schoolData, domain.rangeSignificance[2]);

    const high = (
        <rect
            className={styles.chartDatum}
            data-datum={"quartile"}
            data-significant={highSignificant}
            x={highCx - SQUARE_RADIUS}
            y={yMiddle - SQUARE_RADIUS}
            width={SQUARE_RADIUS * 2}
            height={SQUARE_RADIUS * 2}
            style={{
                transformOrigin: `${highCx}px ${yMiddle}px`,
            }}
        />
    );

    const quartileLine = <line className={styles.quartileLine} x1={lowCx} x2={highCx} y1={yMiddle} y2={yMiddle} />;

    return (
        <g>
            {quartileLine}
            {schoolShape}
            <g>
                {low}
                {median}
                {high}
            </g>
        </g>
    );
};

const Legend = props => {
    const { localiser, batchConfig, visOptions, schoolData } = props;
    ["Average for region", "Average for school", "Average for <PBTS Country>"].forEach(localiser);

    const showComparison = visOptions.domains.some(domain => schoolData[domain.comparison] !== undefined);

    return (
        <div className={"legend"} data-closer style={{ paddingLeft: padding.left }}>
            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    {visOptions.bipolar && (
                        <HexagonSvg className={styles.legendSchoolIcon} data-school data-significant={false} />
                    )}
                    <HexagonSvg className={styles.legendSchoolIcon} data-school />
                </div>
                <span className={"legendLabel"}>
                    {localiser(
                        batchConfig.groupReport
                            ? "Average for region"
                            : batchConfig.nationalReport
                            ? "Average for <PBTS Country>"
                            : "Average for school"
                    )}
                </span>
            </div>
            {visOptions.comparison && showComparison && !batchConfig.nationalReport && (
                <div className={"legendItem"}>
                    <div className={"legendIconGroup"}>
                        <div className={styles.legendSquare} data-rotate data-datum="country" />
                        <div className={styles.legendSquare} data-rotate data-datum="country" data-significant="true" />
                    </div>
                    <span className={"legendLabel"}>{localiser("<PBTS Country>")}</span>
                </div>
            )}
            {!visOptions.comparison && (
                <>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"}>
                            <div className={styles.legendQuartileIcon} data-rotate />
                            <div className={styles.legendQuartileIcon} data-rotate data-significant="true" />
                        </div>
                        <span className={"legendLabel"}>{localiser("Bottom quartile")}</span>
                    </div>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"}>
                            <div className={styles.legendQuartileIcon} data-median />
                            <div
                                className={styles.legendQuartileIcon}
                                data-datum="quartile"
                                data-median
                                data-significant="true"
                            />
                        </div>
                        <span className={"legendLabel"}>{localiser("Second and third quartiles")}</span>
                    </div>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"}>
                            <div className={styles.legendQuartileIcon} />
                            <div className={styles.legendQuartileIcon} data-significant="true" />
                        </div>
                        <span className={"legendLabel"}>{localiser("Top quartile")}</span>
                    </div>
                </>
            )}
        </div>
    );
};

export default BigFive;
