import React from "react";
import useComponentSize from "../../modules/hooks/use-component-size";
import { scaleLinear, scaleBand } from "d3-scale";
import { HexagonPolygonSmall, HexagonSvg } from "../../components/shapes";

import styles from "./horizontal-percentage.module.scss";
import { significance, significanceColumn } from "../../modules/significance";

const DATUM_ORDER = ["school", "oecd", "country"];

const PARA_WIDTH = 330;
const PARA_RIGHT_PADDING = 16;

const padding = {
    top: 66,
    bottom: 0,
    left: PARA_WIDTH + PARA_RIGHT_PADDING,
    right: 36,
};

const SCHOOL_RADIUS = 8;
const CIRCLE_RADIUS = 7;
const DIAMOND_WIDTH = 5;

const BAND_HEIGHT = 26;
const BAND_PADDING_NORMAL = 24;
const BAND_PADDING_TIGHT = 12;

const useDomains = (schoolData, visOptions) => {
    try {
        const bands = visOptions.domains.map(d => d.label);
        const x = [0, 100];
        return {
            bands,
            x,
        };
    } catch (e) {
        console.log(
            "Most likely domains has not been defined in visOptions. This is visOptions: " + JSON.stringify(visOptions)
        );
    }
};

const useScales = (dimensions, domains, visOptions) => {
    if (!dimensions) return undefined;

    const BAND_PADDING = visOptions.tightSpacing ? BAND_PADDING_TIGHT : BAND_PADDING_NORMAL;
    let innerHeight = 100;

    try {
        innerHeight = domains.bands.length * (BAND_HEIGHT + BAND_PADDING * 2);
    } catch (e) {
        console.log(
            "Most likely domains has not been defined in visOptions. Setting innerHeight to 100 by default. This is visOptions: " +
                JSON.stringify(visOptions)
        );
    }
    const totalHeight = padding.top + innerHeight + padding.bottom;

    const frame = {
        top: padding.top,
        right: dimensions.width - padding.right,
        bottom: padding.top + innerHeight,
        left: padding.left,
    };

    const bands = scaleBand()
        .domain(domains.bands)
        .rangeRound([frame.top, frame.bottom])
        .paddingOuter(0)
        .paddingInner(BAND_PADDING / innerHeight);

    const bandwidth = bands.bandwidth();

    const x = scaleLinear().domain(domains.x).rangeRound([frame.left, frame.right]);

    return {
        frame,
        bands,
        x,
        bandwidth,
        height: totalHeight,
        BAND_PADDING,
    };
};

const HorizontalPercentage = props => {
    console.log("----- Starting to render HorizontalPercentage: " + props.id);
    const { visOptions, schoolData } = props;
    const ref = React.useRef();
    const dimensions = useComponentSize(ref);
    const domains = useDomains(schoolData, visOptions);
    const scales = useScales(dimensions, domains, visOptions);

    const chartProps = {
        ...props,
        dimensions,
        domains,
        scales,
    };

    let chartContent;
    let labelContent;
    if (scales) {
        chartContent = (
            <>
                <AxisGrid {...chartProps} />
                <Data {...chartProps} />
            </>
        );
        labelContent = <DomainLabels {...chartProps} />;
    }

    return (
        <div className={styles.container}>
            <svg
                className={styles.svg}
                ref={ref}
                height={scales && scales.height}
                style={{ height: scales && scales.height }}
            >
                {chartContent}
            </svg>
            {labelContent}
            {scales && <Legend {...chartProps} />}
        </div>
    );
};

const AxisGrid = props => {
    const { visOptions, localiser, scales, dimensions } = props;

    const xTicks = scales.x.ticks(5);

    // Lines protrude past the axis a bit.
    const linesStartY = scales.frame.top - 8;

    return (
        <g>
            <g>
                {xTicks.map((yVal, i) => (
                    <line
                        key={yVal}
                        className={"gridLine"}
                        y1={linesStartY}
                        y2={scales.frame.bottom}
                        x1={scales.x(yVal)}
                        x2={scales.x(yVal)}
                        data-bottom={i === 0}
                    />
                ))}
            </g>
            <g>
                {xTicks.map(yVal => (
                    <text key={yVal} className={"gridLabel"} x={scales.x(yVal)} y={linesStartY - 8} data-align="start">
                        {yVal}%
                    </text>
                ))}
            </g>
            <g>
                <line
                    className={"axisLine"}
                    x1={scales.frame.left}
                    x2={scales.frame.right + 36}
                    y1={scales.frame.top}
                    y2={scales.frame.top}
                />
                <text className={"axisText"} x={dimensions.width} y={16} data-align="end">
                    {localiser(visOptions.xAxisLabel || "Percentage of students")}
                </text>
            </g>
        </g>
    );
};

// TODO: localise all domains.
const DomainLabels = props => {
    const { visOptions, localiser, scales } = props;

    const labels = visOptions.domains.map(domain => {
        const yStart = scales.bands(domain.label);
        const yMid = yStart + scales.bandwidth / 2;
        return (
            <div
                key={domain.label}
                className={styles.domainLabel}
                style={{
                    top: yMid,
                    maxWidth: PARA_WIDTH,
                }}
            >
                {localiser(domain.label)}
            </div>
        );
    });

    return <div className={styles.labelContainer}>{labels}</div>;
};

const Data = props => {
    return (
        <g>
            {props.visOptions.domains.map(d =>
                props.visOptions.range ? (
                    <RangeData key={d.label} {...props} domain={d} />
                ) : (
                    <DomainData key={d.label} {...props} domain={d} />
                )
            )}
        </g>
    );
};

// VARIATION: show line between points.
const RangeData = props => {
    const { domain, schoolData, scales } = props;
    const yStart = scales.bands(domain.label);
    const cy = yStart + scales.bandwidth / 2;

    const symbols = domain.columns.map((column, columnIndex) => {
        const value = schoolData[column.value];

        // If we don't have either column, we count it as significant always.
        const significant = column.significant
            ? significanceColumn(schoolData, column.significant)
            : column.error
            ? significance(value, schoolData[column.error])
            : true;

        const cx = scales.x(value || 0);

        // School value
        if (columnIndex === 1) {
            const yOffset = -1;
            return (
                <HexagonPolygonSmall
                    key={columnIndex}
                    className={styles.chartDatum}
                    transform={`translate(${cx - SCHOOL_RADIUS} ${cy - SCHOOL_RADIUS + yOffset})`}
                    data-significant={significant}
                    data-datum={"school"}
                    style={{
                        transformOrigin: `${cx}px ${cy + yOffset}px`,
                    }}
                />
            );
        }

        return (
            <rect
                key={columnIndex}
                className={styles.rangeDatum}
                x={cx - DIAMOND_WIDTH}
                y={cy - DIAMOND_WIDTH}
                width={2 * DIAMOND_WIDTH}
                height={2 * DIAMOND_WIDTH}
                style={{
                    transformOrigin: `${cx}px ${cy}px`,
                    transform: columnIndex === 0 && "rotate(45deg)",
                }}
                data-significant={significant}
            />
        );
    });

    const lineStart = scales.x(schoolData[domain.columns[0].value]);
    const lineEnd = scales.x(schoolData[domain.columns[2].value]);

    const line = <line className={styles.rangeLine} x1={lineStart} x2={lineEnd} y1={cy} y2={cy} />;

    return (
        <g>
            {line}
            {symbols[1]}
            {symbols[0]}
            {symbols[2]}
        </g>
    );
};

const DomainData = props => {
    const { domain, schoolData, scales, visOptions } = props;
    const yStart = scales.bands(domain.label);
    const y = yStart + scales.bandwidth / 2;

    const datumOrder = visOptions.showOECD !== undefined && !visOptions.showOECD ? ["school", "country"] : DATUM_ORDER;

    const symbols = datumOrder.map(datumName => {
        const v = domain.columns[datumName];
        const value = schoolData[v.value || v];
        const significant = v.significant
            ? significanceColumn(schoolData, v.significant)
            : v.error
            ? significance(value, schoolData[v.error])
            : true;

        if (v === undefined || schoolData[v.value] === undefined) {
            console.log("----- ----- Couldn't find either " + JSON.stringify(v) + " or " + v.value + " in schoolData");
            console.log("----- ----- Not rendering symbols in DomainData");
            return null;
        } else {
            const x = scales.x(value);

            if (datumName === "school") {
                const yOffset = -1;
                return (
                    <HexagonPolygonSmall
                        key={datumName}
                        className={styles.chartDatum}
                        transform={`translate(${x - SCHOOL_RADIUS} ${y - SCHOOL_RADIUS + yOffset})`}
                        data-significant={significant}
                        data-datum={datumName}
                        style={{
                            transformOrigin: `${x}px ${y + yOffset}px`,
                        }}
                    />
                );
            } else if (datumName === "country") {
                return (
                    <rect
                        key={datumName}
                        className={styles.chartDatum}
                        data-significant={significant}
                        data-datum={datumName}
                        x={x - DIAMOND_WIDTH}
                        y={y - DIAMOND_WIDTH}
                        width={2 * DIAMOND_WIDTH}
                        height={2 * DIAMOND_WIDTH}
                        style={{
                            transformOrigin: `${x}px ${y}px`,
                            transform: "rotate(45deg)",
                        }}
                    />
                );
            } else {
                return (
                    <circle
                        key={datumName}
                        className={styles.chartDatum}
                        cx={x}
                        cy={y}
                        r={CIRCLE_RADIUS}
                        data-significant={significant}
                        data-datum={datumName}
                    />
                );
            }
        }
    });

    let fillRect = "";

    if (schoolData[domain.columns.school] === undefined && schoolData[domain.columns.school.value] === undefined) {
        console.log(
            "----- ----- Couldn't find either " +
                JSON.stringify(domain.columns.school) +
                " or " +
                domain.columns.school.value +
                " in schoolData"
        );
        console.log("----- ----- Not rendering fillRect in DomainData");
    } else {
        const fillEndAt = scales.x(schoolData[domain.columns.school.value] || schoolData[domain.columns.school]);
        const fillWidth = fillEndAt - scales.frame.left;

        fillRect = (
            <rect
                className={styles.schoolFillRect}
                x={scales.frame.left}
                y={y - BAND_HEIGHT / 2}
                width={fillWidth}
                height={BAND_HEIGHT}
            />
        );
    }

    return (
        <g>
            {fillRect}
            {symbols}
        </g>
    );
};

const Legend = props => {
    const { localiser, visOptions, batchConfig } = props;

    ["Average for region", "Average for school", "Average for <PBTS Country>"].forEach(localiser);

    return (
        <div className={"legend"} style={{ paddingLeft: padding.left }}>
            <div className={"legendItem"}>
                <div className={"legendIconGroup"}>
                    <HexagonSvg className={"legendIcon"} data-school />
                </div>
                <span className={"legendLabel"}>
                    {visOptions.range
                        ? localiser(
                              batchConfig.groupReport
                                  ? "Average for region"
                                  : batchConfig.nationalReport
                                  ? "Average for <PBTS Country>"
                                  : "Average for school"
                          )
                        : props.yourSchool}
                </span>
            </div>
            {visOptions.range ? (
                <>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"} style={{ height: 18.4 }}>
                            <div className={styles.legendSquare} data-rotate />
                            <div className={styles.legendSquare} data-rotate data-significant="true" />
                        </div>
                        <span className={"legendLabel"}>{localiser(visOptions.rangeLabels[0]).replace("|", "\n")}</span>
                    </div>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"}>
                            <div className={styles.legendSquare} />
                            <div className={styles.legendSquare} data-significant="true" />
                        </div>
                        <span className={"legendLabel"}>{localiser(visOptions.rangeLabels[1]).replace("|", "\n")}</span>
                    </div>
                </>
            ) : (
                <>
                    <div className={"legendItem"}>
                        <div className={"legendIconGroup"}>
                            <div className={styles.legendSquare} data-rotate data-datum="country" />
                            <div
                                className={styles.legendSquare}
                                data-rotate
                                data-datum="country"
                                data-significant="true"
                            />
                        </div>
                        <span className={"legendLabel"}>
                        {(visOptions.showPBTSCountry === true) && (batchConfig.countryNamePbts)} 
                        {(visOptions.showPBTSCountry !== true) && (batchConfig.countryName)}
                        </span>
                    </div>
                    {(visOptions.showOECD === undefined || visOptions.showOECD !== false) && (
                        <div className={"legendItem"}>
                            <div className={"legendIconGroup"}>
                                <div className={styles.legendCircle} data-datum="oecd" />
                                <div className={styles.legendCircle} data-datum="oecd" data-significant="true" />
                            </div>
                            <span className={"legendLabel"}>{localiser("OECD")}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default HorizontalPercentage;
