import React from "react";
import { fetchReportData } from "./modules/fetch-data";
import { makeLocaliser } from "./modules/localiser";
import packagejson from "../package.json";

import { ExecutiveSummary, ExecutiveSummaryCovid } from "./visualisations/executive-summary";
import VerticalPerformance from "./visualisations/vertical-performance/vertical-performance";
import VerticalPerformanceTable from "./visualisations/vertical-performance-table";
import HorizontalPercentage from "./visualisations/horizontal-percentage/horizontal-percentage";
import Scatterplot from "./visualisations/scatterplot";
import BigFive from "./visualisations/big-five";
import Baseline from "./visualisations/baseline/baseline";
import Table from "./visualisations/table";
import GroupedHorizontalPercentage from "./visualisations/grouped-horizontal-percentage";
import VerticalPerformanceTrends from "./visualisations/vertical-performance-trends/vertical-performance-trends";

const COMPONENTS = {
    ExecutiveSummary: ExecutiveSummary,
    ExecutiveSummaryCovid: ExecutiveSummaryCovid,
    VerticalPerformance: VerticalPerformance,
    VerticalPerformanceTable: VerticalPerformanceTable,
    HorizontalPercentage: HorizontalPercentage,
    Scatterplot: Scatterplot,
    BigFive: BigFive,
    Baseline: Baseline,
    Table: Table,
    GroupedHorizontalPercentage: GroupedHorizontalPercentage,
    VerticalPerformanceTrends: VerticalPerformanceTrends,
};

// the component could already be an object, return it. Or return the mapped component by string name.
const getComponent = c => {
    try {
        return typeof c === "string" ? COMPONENTS[c] : c;
    } catch (e) {
        console.error("No component found with name " + c + ". Valid choices are: " + Object.keys(COMPONENTS));
    }
};

function App(props) {
    const { visualisationId } = props;

    const [schoolData, setSchoolData] = React.useState(null);
    const [pisaData, setPisaData] = React.useState(null);
    const [batchConfig, setBatchConfig] = React.useState(null);
    const [batchLocalisation, setBatchLocalisation] = React.useState(null);

    const visualisations = React.useMemo(() => {
        if (!batchConfig) return [];
        return Object.entries(batchConfig.options).map(([key, options]) => ({
            id: key,
            ...options,
        }));
    }, [batchConfig]);

    React.useEffect(() => {
        fetchReportData(props).then(({ schoolData, pisaData, batchConfig, batchLocalisation }) => {
            setSchoolData(schoolData);
            setPisaData(pisaData);
            setBatchConfig(batchConfig);
            setBatchLocalisation(batchLocalisation);
        });
    }, [props]);

    const dependencies = [schoolData, pisaData, batchConfig, batchLocalisation];
    const loaded = React.useMemo(() => dependencies.every(Boolean), [dependencies]);

    if (!loaded) return null;

    const passDownProps = {
        schoolData,
        pisaData,
        batchConfig,
        batchLocalisation,
        params: props,
    };

    const vises = visualisations
        .filter(d => (visualisationId ? visualisationId.split(",").includes(d.id) : true))
        .map(d => {
            const VisComponent = getComponent(d.component);
            const localiser = makeLocaliser(batchLocalisation, d.id);
            ["Your Region", "Your School", "<PBTS Country>"].forEach(localiser);
            const yourSchool = localiser(
                batchConfig.groupReport ? "Your Region" : batchConfig.nationalReport ? "<PBTS Country>" : "Your School"
            );
            const visDiv = (
                <div
                    id={d.id}
                    className="visualisation"
                    key={d.id}
                    style={{ marginBottom: 100, display: "inline-block" }}
                >
                    <VisComponent
                        {...passDownProps}
                        localiser={localiser}
                        visOptions={d.visOptions}
                        id={d.id}
                        yourSchool={yourSchool}
                    />
                </div>
            );
            if (props.print) {
                return (
                    <div style={{ pageBreakInside: "avoid" }} key={d.id}>
                        <h2 style={{ marginBottom: 50 }}>Figure {d.prefix ? d.prefix + ": " + d.id : d.id}</h2>
                        {visDiv}
                    </div>
                );
            }

            return (
                <div>
                    <h2>{d.prefix ? d.prefix + ": " + d.id : d.id}</h2>
                    {visDiv}
                </div>
            );
        });

    return (
        <div className="App">
            <div style={{ display: visualisationId ? "None" : "Block" }}>
                <p>
                    PBTS Chart Generator, timestamp: {packagejson.timestamp}, version: {packagejson.version}
                </p>
                <h4>
                    {batchConfig.groupReport
                        ? "Group Report"
                        : batchConfig.nationalReport
                        ? "National Report"
                        : "School Report"}
                </h4>
                <a style={{ display: "block", marginBottom: 128 }} href={window.location.href + "&generateReport=true"}>
                    Click here to generate the report for this{" "}
                    <span>{batchConfig.groupReport ? "Group" : "School"}</span>
                    ...
                </a>
            </div>
            {vises}
        </div>
    );
}

export default App;
