// this file is duplicated in the back-end and front-end.

import moment from "moment";

export const formatPercent = (decimals = 0) => v => v.toFixed(decimals) + "%";

export const formatDecimal = (raw, locale, decimals = 2, minimumFractionDigits = 0) =>
	raw.toLocaleString(locale, { maximumFractionDigits: decimals, minimumFractionDigits });

export const formatDate = (raw, dateFormatString) => moment(raw).format(dateFormatString);

export const formatComma = (raw, locale) => raw.toLocaleString(locale);

export const formatBreaks = raw => raw.replace(/\|/g, "\n");

const FORMATS = {
	commaFormat: (value, schoolData, localiser) => formatComma(value, localiser("language-tag")),
	decimalFormat: (value, schoolData, localiser) => formatDecimal(value, localiser("language-tag"), 2),
	percentFormat: formatPercent(0),
};

// the format could already be an object, return it. Or return the mapped format by string name.
export const getFormat = f => {
	try {
		return typeof f === "string" ? FORMATS[f] : f;
	} catch (e) {
		console.error("No format found with name " + f + ". Valid choices are: " + Object.keys(FORMATS));
	}
};
