import React from "react";
import ReactDOM from "react-dom";
import qs from "qs";
import "./styles/index.scss";
import "./styles/charts.scss";
import App from "./app";
import isDev from "./modules/is-dev";
import GenerateReportPage from "./components/generate-report-page";

const params = qs.parse(window.location.search.slice(1));
const props = {
    year: parseInt(params.year),
    countryCode: params.countryCode,
    batchNumber: params.batchNumber,
    schoolId: params.schoolId,
    visualisationId: params.visualisationId,
    print: params.print === "true",
    auth: params.auth,
    generateReport: params.generateReport === "true",
    openReport: params.openReport === "true",
    reuseReport: params.reuseReport === "true",
    // WH
    reportFileOutput: params.reportFileOutput === "true",
    pdfKey: params.pdfKey,
    localisationLanguage: params.localisationLanguage,
};

if (!isDev) {
    let paramsToCheck = ["year", "countryCode", "batchNumber", props.generateReport ? null : "schoolId"].filter(
        Boolean
    );
    paramsToCheck.forEach(field => {
        let valid = true;
        const value = props[field];

        if (value === undefined || value === null) valid = false;
        else if (isNaN(value) && !value.length) valid = false;

        if (!valid) throw new Error(`Missing/invalid URL param "${field}".`);
    });
}
if (props.generateReport || props.openReport) {
    ReactDOM.render(<GenerateReportPage {...props} />, document.getElementById("root"));
} else {
    console.log("Settings:");
    console.table(props);
    ReactDOM.render(<App {...props} />, document.getElementById("root"));
}
